import axios from 'axios';
import Jsona from 'jsona';
import { AuthService } from '../services/auth-service';
import WorkspaceUtils from '../utils/workspace-utils';
import TubeError from '@/lib/tube-error';
import googleRecaptchaService from '@/app/services/google-recaptcha-service';
import { EmailSetting } from '../player/controllers/models';
declare let PARTNER_MODE: boolean;

class ApiClient {
  private dataFormatter: Jsona;
  private edgeApiEnabled = false;
  private _workspaceName!: string;

  private authService!: AuthService;
  constructor() {
    this.dataFormatter = new Jsona();
  }

  setAuthService(authService: AuthService) {
    this.authService = authService;
  }

  setEdgeApiEnabled(edgeApiEnabled) {
    this.edgeApiEnabled = edgeApiEnabled;
  }

  get workspaceName() {
    return this.authService.getWorkspaceName();
  }

  login(payload) {
    return this._post('/users/login', {}, payload, true, true);
  }

  hubspotToken() {
    return this._post('/users/hubspot_token', {}, {}, true, true);
  }

  celloToken() {
    return this._post('/users/cello_token', {}, {}, true, true);
  }

  referralAttribution(payload) {
    return this._post('/attributions/referral', {}, payload, false, true, false)
  }

  commonLogin(payload) {
    return this._post('/users/common_login', {}, payload, false, true);
  }

  ssoLogin(payload) {
    return this._post('/users/sso_login', {}, payload, false, true);
  }

  preCheck(token) {
    return this._get(`/users/pre_check?token=${token}`, {}, false, true, false);
  }

  domainNameCheck(domainName) {
    return this._get(
      `/workspaces/name_check?name=${domainName}`,
      {},
      false,
      true,
      false
    );
  }

  genDomainNameFor(displayName) {
    return this._get(
      `/workspaces/domain_name?name=${displayName}`,
      {},
      false,
      true,
      false
    );
  }

  userEvents(eventName) {
    return this._post(
      `/users/user_events`,
      {},
      { event_name: eventName },
      true,
      true,
      true
    );
  }

  userEventsGet(eventName) {
    return this._get(
      `/users/user_events/count?event_name=${eventName}`,
      {},
      true,
      true,
      true
    );
  }

  getUsers() {
    return this._get('/users/list', {}, true, true, true);
  }

  verifySignupEmail(googleToken) {
    const formData = new FormData();
    formData.append('google_token', googleToken);
    formData.append('signup', 'true');
    return this._post(
      '/users/verify',
      { 'Content-Type': 'application/x-www-form-urlencoded' },
      formData,
      false,
      true,
      false
    );
  }

  sendOTP(email) {
    return googleRecaptchaService.execute('send_otp').then((token) => {
      return this._post(
        '/users/otp',
        {},
        { email, recaptcha_token: token },
        false,
        true,
        false
      );
    });
  }

  createGoogleUser(email, googleToken) {
    return this._post(
      '/users/google',
      {},
      { google_token: googleToken, email },
      false,
      true,
      false
    );
  }

  verifyOtp(email, otp, attributions) {
    return googleRecaptchaService.execute('verify_otp').then((token) => {
      return this._post(
        '/users/verify_otp',
        {},
        { email, otp, recaptcha_token: token, attributions },
        false,
        true,
        false
      );
    });
  }

  setPassword(email, password, token) {
    return this._post(
      '/users/password',
      {},
      { email, password, auth_jwt_token: token },
      false,
      true,
      false
    );
  }

  verifyUser(email, signupMode, workspace?) {
    return googleRecaptchaService.execute('verify_user').then((token) => {
      return this._post(
        '/users/verify',
        {},
        {
          email,
          workspace,
          recaptcha_token: token,
          signup: signupMode,
          reseller_mode: PARTNER_MODE,
        },
        false,
        true,
        false
      );
    });
  }

  loginViaPassword(email, password) {
    return this._post(
      '/users/login_via_password',
      {},
      { email, password, reseller_mode: PARTNER_MODE },
      false,
      true,
      false
    );
  }

  eligibleMemberships(googleToken, jwtToken, email) {
    const formData = new FormData();
    googleToken && formData.append('google_token', googleToken);
    jwtToken && formData.append('auth_jwt_token', jwtToken);
    formData.append('email', email);
    return this._post(
      '/users/eligible_memberships',
      {},
      formData,
      false,
      true,
      false
    );
  }

  joinWorkspace(googleToken, jwtToken, email, workspaceName, membershipId) {
    const formData = new FormData();
    googleToken && formData.append('google_token', googleToken);
    jwtToken && formData.append('auth_jwt_token', jwtToken);
    formData.append('email', email);
    formData.append('workspace_name', workspaceName);
    if (!!membershipId) {
      formData.append('workspace_membership_id', membershipId);
    }
    return this._post('/users/auto_join', {}, formData, false, true, false);
  }

  createWorkspace(
    googleToken,
    jwtToken,
    workspaceName,
    domainName,
    autoJoinDomain,
    attributions
  ) {
    const formData = {
      domain: domainName,
      auto_join_domain: autoJoinDomain,
      display_name: workspaceName,
      attributions: attributions
    };
    if (!!googleToken) {
      formData['google_token'] = googleToken;
    }
    if (!!jwtToken) {
      formData['auth_jwt_token'] = jwtToken;
    }
    return this._post('/workspaces', {}, formData, false, true, false);
  }

  updateWorkspace(displayName, logo, brandColor) {
    const formData = new FormData();
    formData.append('display_name', '' + displayName);
    logo && formData.append('logo', logo);
    formData.append('brand_color', brandColor);
    return this._put(`/workspaces/${this.workspaceName}`, {}, formData);
  }

  updateGuideTheme(guideTheme) {
    return this._put(`/workspace/${this.workspaceName}/guide_theme`,
      {},
      {
        background_color: guideTheme['backgroundColor'],
        button_color: guideTheme['buttonColor'],
        text_color: guideTheme['textColor'],
        spotlight_color: guideTheme['spotlightColor'],
      },
    );
  }

  fetchProfileQuestions({ authToken, email }) {
    // TODO fix me against convention
    return this._post(
      `/users/fetch_profile`,
      {},
      { auth_jwt_token: authToken, email },
      false,
      true,
      false
    );
  }

  getLoginProfileQuestions() {
    // TODO fix me against convention
    return this._get(`/users/profile`, {}, false, true, true);
  }

  getCourseAuthors(id) {
    return this._get(`/courses/${id}/authors`, {}, true, true, true);
  }

  getCourseUsers(id) {
    return this._get(`/courses/${id}/users`, {}, true, true, true);
  }

  getFolders(folderId) {
    return this._get(`/folders/${folderId}/folders`, {}, true, true, true).then(
      (data) => {
        return new Promise((resolve, reject) => {
          resolve({
            data: this.dataFormatter.deserialize(data) as any,
            meta: data.meta,
          });
        });
      }
    );
  }

  getContents(folderId, page, filters: any = null) {
    const params = new URLSearchParams();
    if (page) {
      params.append('page', page);
    }
    if (filters) {
      const { sortOrder } = filters;
      if (sortOrder) {
        params.append('sort_order', sortOrder);
        params.append('sort_by', 'updated_at');
      }
    }
    const queryString = params.toString();
    return this._get(
      `/folders/${folderId}/contents?${queryString}`,
      {},
      true,
      true
    ).then((data) => {
      return new Promise((resolve, reject) => {
        resolve({
          data: this.dataFormatter.deserialize(data) as any,
          meta: data.meta,
        });
      });
    });
  }

  getSpaces() {
    return this._get('/spaces');
  }

  getVideos(folderId, offset, limit, v2Only = false, sortBy?, sortOrder?) {
    return this._get(
      `/folders/${folderId}/videos?for_v2=${v2Only}&offset=${offset}&limit=${limit}&sort_by=${sortBy}&sort_order=${sortOrder}`,
      {},
      true,
      false,
      true
    );
  }

  getFolderArticles(folderId, offset, limit, sortBy?, sortOrder?) {
    return this._get(
      `/folders/${folderId}/articles?&offset=${offset}&limit=${limit}&sort_by=${sortBy}&sort_order=${sortOrder}`,
      {}
    );
  }

  getFolderGuides(folderId, offset, limit, sortBy?, sortOrder?) {
    return this._get(
      `/folders/${folderId}/guides?&offset=${offset}&limit=${limit}&sort_by=${sortBy}&sort_order=${sortOrder}`,
      {}
    );
  }

  getFolderDocuments(folderId, offset, limit, sortBy?, sortOrder?) {
    return this._get(
      `/folders/${folderId}/documents?&offset=${offset}&limit=${limit}&sort_by=${sortBy}&sort_order=${sortOrder}`,
      {}
    );
  }

  setFolder(query) {
    return this._post('/folders', {}, query, true, false, true);
  }

  setFolderV2(query) {
    return this._post('/folders?v2=true', {}, query, true, true, true).then(
      (data) => {
        return new Promise((resolve, reject) => {
          resolve({
            content: this.dataFormatter.deserialize(data) as any,
            meta: data.meta,
          });
        });
      }
    );
  }

  deleteFolder({ id, query }) {
    return this._delete(`/folders/${id}`, {}, query, true, false, true);
  }

  deleteFolderV2({ id, query }) {
    return this._delete(`/folders/${id}`, {}, query, true, true).then(
      (data) => {
        return new Promise((resolve, reject) => {
          resolve({
            content: this.dataFormatter.deserialize(data) as any,
            meta: data.meta,
          });
        });
      }
    );
  }

  renameFolder({ name, id }) {
    return this._put(
      `/folders/${id}`,
      {},
      {
        name,
      },
      true,
      false,
      true
    );
  }

  moveFolder(body) {
    return this._post('/folders/children', {}, body, true, true)
      .then(() => {
        return Promise.all([
          this.getFolders(body['current_parent_id']),
          this.getVideos(body['current_parent_id'], 0, 10),
        ]);
      })
      .then((data) => {
        return {
          folders: data[0].data,
          videos: data[1],
        };
      });
  }

  moveFolderV2(body) {
    return this._post('/folders/children', {}, body, true, true)
      .then(() => {
        const search = new URLSearchParams(window.location.search);
        const page = search.get('page') || 1;
        return Promise.resolve(
          this.getContents(body['current_parent_id'], page, null)
        );
      })
      .then((data) => ({
        content: data.data as any,
        meta: data.meta,
      }));
  }

  getFolderHierarchy() {
    return this._get('/folders/folder_hierarchy', {}, true, true, true);
  }

  setupUserProfile({ authToken, email, name, profile, phone }) {
    return this._put(
      `/users/setup`,
      {},
      { auth_jwt_token: authToken, email, name, profile, phone },
      false,
      true,
      false
    );
  }

  updateProfileData(
    name,
    bio,
    designation,
    profile,
    picture,
    appEmailNotifications?
  ) {
    const formData = new FormData();
    formData.append('name', name);
    if (bio != null) {
      formData.append('bio', bio);
    }
    if (designation != null) {
      formData.append('designation', designation);
    }
    formData.append('profile', JSON.stringify(profile));
    picture && formData.append('picture', picture);
    if (appEmailNotifications != null) {
      formData.append('enable_app_notifications', appEmailNotifications);
    }
    return this._put(`/users/name`, {}, formData);
  }

  getSecuritySetting() {
    return this._get(`/workspaces/${this.workspaceName}/security_settings`, {}, true, false, true);
  }

  updateSecuritySetting(
    samlConfig,
  ) {
    return this._post(`/workspaces/${this.workspaceName}/security_settings`, {}, {
      samlConfig
    });
  }

  updatePassword(oldPassword, newPassword) {
    return this._put(
      `/users/password`,
      {},
      { old_password: oldPassword, new_password: newPassword },
      true,
      true
    );
  }

  fetchWorkspaceInfo() {
    return this._get(`/workspaces/${this.workspaceName}`, {});
  }

  fetchIntegrationSettings() {
    return this._get(`/workspaces/integrations`, {});
  }

  addElevenlabsVoice(voiceId) {
    return this._put(`/integrations/add_elevenlabs_voice`, {}, {
      voice_id: voiceId
    })
  }

  removeElevenlabsVoice(voiceId) {
    return this._put(`/integrations/remove_elevenlabs_voice`, {}, {
      voice_id: voiceId
    })
  }

  connectWithElevenLabs(apiKey) {
    return this._put(`/integrations/elevenlabs`, {}, {
      api_key: apiKey
    })
  }

  disconnectElevenLabs() {
    return this._delete(`/integrations/elevenlabs`, {}, {}, true, false)
  }

  youtubeOuathUrl() {
    return this._get(`/integrations/youtube_connect`, {}, true, true, true);
  }

  youtubeUpdateCallback(query) {
    const queryParam = Object.keys(query)
      .map(function (k) {
        return encodeURIComponent(k) + '=' + encodeURIComponent(query[k]);
      })
      .join('&');
    return this._post(`/integrations/youtube_callback?${queryParam}`, {});
  }

  youtubeDisconnect() {
    return this._put(`/integrations/youtube_disconnect?`);
  }

  intercomOuathUrl() {
    return this._post(`/integrations/intercom_connect`, {}, true, true, true);
  }


  salesforceOauthUrl() {
    return this._post(`/integrations/salesforce_connect`, {}, true, true, true);
  }

  salesforcePackageCheck() {
    return this._get(`/integrations/salesforce_package_check`, {});
  }

  intercomUpdateDirectCallback(query) {
    const queryParam = Object.keys(query)
      .map(function (k) {
        return encodeURIComponent(k) + '=' + encodeURIComponent(query[k]);
      })
      .join('&');
    return this._post(
      `/integrations/intercom_direct_callback?${queryParam}`,
      {}
    );
  }

  salesforceUpdateDirectCallback(query) {
    const queryParam = Object.keys(query)
      .map(function (k) {
        return encodeURIComponent(k) + '=' + encodeURIComponent(query[k]);
      })
      .join('&');
    return this._post(
      `/integrations/salesforce_callback?${queryParam}`,
      {}
    );
  }

  updateSalesforceSettings(createContactIfMissing) {
    return this._put(
      `/integrations/salesforce_settings`,
      {},
      {
        create_contact_if_missing: createContactIfMissing,
      }
    );
  }

  salesforceDisconnect() {
    return this._put(`/integrations/salesforce_disconnect?`);
  }

  intercomDisconnect() {
    return this._put(`/integrations/intercom_disconnect?`);
  }

  intercomUpdateCallback(code, workspaceName, apiKey) {
    return this._post(
      `/integrations/intercom_callback`,
      {},
      {
        code,
        workspace_name: workspaceName,
        api_key: apiKey,
      },
      false,
      true,
      false
    );
  }

  updateIntercomSettings(useEmailToCreate) {
    return this._put(
      `/integrations/intercom_settings`,
      {},
      {
        use_email_to_create: useEmailToCreate,
      }
    );
  }

  stripeConnect() {
    return this._post(`/integrations/stripe_connect`, {}, {}, true, true);
  }

  stripeCallback(data) {
    return this._post(
      `/integrations/stripe_callback`,
      {},
      data
    );
  }

  stripeDisconnect() {
    return this._put(`/integrations/stripe_disconnect`, {}, {}, true, true);
  }

  fetchApiKeys() {
    return this._get(`/api_keys`, {});
  }

  regenerateApiKey() {
    return this._post('/api_keys/re_generate', {}, {});
  }

  logout(params = {}) {
    return this._post('/users/logout', {}, params, true, true, true);
  }

  activateToken(token) {
    return this._put(
      `/workspace_memberships/${token}/activate`,
      {},
      {},
      false,
      true,
      false
    );
  }

  verifyActivateToken(token) {
    return googleRecaptchaService
      .execute('activate_token')
      .then((recaptchaToken) => {
        return this._post(
          `/users/verify_activate_token`,
          {},
          { token, recaptcha_token: recaptchaToken },
          false,
          true,
          false
        );
      });
  }

  canJoinWithInviteToken(token) {
    return this._put(
      `/workspace_memberships/${token}/pre_check`,
      {},
      {},
      true,
      true,
      true
    );
  }

  createVideo(title, file, type, currentFolderId?) {
    const formData = new FormData();
    formData.append('title', title);
    formData.append('file', file);
    formData.append('type', type);

    if (currentFolderId) {
      formData.append('folder_id', currentFolderId);
    }

    return this._post(
      `/videos`,
      { 'Content-Type': 'application/x-www-form-urlencoded' },
      formData,
      true,
      true,
      true
    );
  }

  uploadNewVerison(videoId, title, file, type, currentFolderId?) {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('type', type);

    if (title) {
      formData.append('title', title);
    }
    if (currentFolderId) {
      formData.append('folder_id', currentFolderId);
    }

    return this._post(
      `/videos/${videoId}/upload_new_version`,
      { 'Content-Type': 'application/x-www-form-urlencoded' },
      formData,
      true,
      true,
      true
    );
  }

  fetchOnboardingTasks() {
    return this._get(`/on_boarding_tasks`, {}, true, true, true);
  }

  updateOnboardingTasks(id) {
    return this._put(`/on_boarding_tasks/${id}`, {}, {}, true, true, true);
  }

  fetchVideos(offset = 0, limit = 10, forHub = false) {
    return this._get(
      `/videos?offset=${offset}&limit=${limit}&for_hub=${forHub}`,
      {}
    );
  }

  fetchInstantVideos(offset = 0, limit = 10) {
    return this._get(`/videos?offset=${offset}&limit=${limit}&type=insta`, {});
  }

  editInstantVideos(videoId, srcVideoId) {
    return this._post(
      `/videos/${videoId}/video_tracks/import_instant`,
      {},
      { src_video_id: srcVideoId }
    );
  }

  fetchTrashVideos(offset = 0, limit = 10) {
    return this._get(`/trash/videos?offset=${offset}&limit=${limit}`, {});
  }

  fetchTrashFolders(offset = 0, limit = 150) {
    return this._get(`/trash/folders?offset=${offset}&limit=${limit}`, {});
  }

  fetchTrashContents(folderId, page) {
    return this._get(
      `/trash/contents?page=${page}&space_id=${folderId}`,
      {},
      true,
      true
    ).then((data) => {
      return new Promise((resolve, reject) => {
        resolve({
          data: this.dataFormatter.deserialize(data) as any,
          meta: data.meta,
        });
      });
    });
  }

  fetchVideosForImport(offset = 0, limit = 10) {
    return this._get(
      `/videos?offset=${offset}&limit=${limit}&import_sr=${true}`,
      {}
    );
  }

  fetchVideosForImportPt(offset = 0, limit = 10) {
    return this._get(
      `/videos?offset=${offset}&limit=${limit}&import_pt=${true}`,
      {}
    );
  }

  search(search, offset = 0, limit = 10) {
    return this._get(
      `/videos/search?offset=${offset}&limit=${limit}&query=${search}`,
      {},
      true,
      true,
      true
    );
  }

  searchVideos(
    search,
    offset = 0,
    limit = 10,
    importSr = false,
    folderID = ''
  ) {
    return this._get(
      `/videos/search_videos?offset=${offset}&limit=${limit}&query=${search}&import_sr=${importSr}&folder_id=${folderID}`,
      {},
      true,
      true,
      true
    );
  }

  searchGuides(
    search,
    offset = 0,
    limit = 10,
    importSr = false,
    folderID = ''
  ) {
    return this._get(
      `/guides/search_guides?offset=${offset}&limit=${limit}&query=${search}&import_sr=${importSr}&folder_id=${folderID}`,
      {},
      true,
      true
    );
  }

  searchArticles(
    search,
    offset = 0,
    limit = 10,
    importSr = false,
    folderID = ''
  ) {
    return this._get(
      `/articles/search_articles?offset=${offset}&limit=${limit}&query=${search}&import_sr=${importSr}&folder_id=${folderID}`,
      {},
      true,
      true
    );
  }

  searchAllVideos(
    search,
    offset = 0,
    limit = 10,
    folderID = ''
  ) {
    return this._get(
      `/videos/search_videos?offset=${offset}&limit=${limit}&query=${search}&all=true&folder_id=${folderID}`,
      {},
      true,
      true,
      true
    );
  }

  learners(page = 1, size = 20, sortBy = 'created_at', sortOrder = 'desc') {
    return this._get(
      `/customers/search?page=${page}&size=${size}&sort_by=${sortBy}&sort_order=${sortOrder}`,
      {},
      true,
      true,
      true
    ).then((data) => {
      return new Promise((resolve, reject) => {
        resolve({
          data: this.dataFormatter.deserialize(data) as any,
          meta: data.meta,
        });
      });
    });
  }

  filterLearners(
    page = 1,
    size = 20,
    sortBy = 'created_at',
    sortOrder = 'desc',
    conditions,
    groupId
  ) {
    return this._post(
      `/customers/search?page=${page}&size=${size}&sort_by=${sortBy}&sort_order=${sortOrder}`,
      {},
      { conditions, group_id: groupId },
      true,
      true,
      true
    ).then((data) => {
      return new Promise((resolve, reject) => {
        resolve({
          data: this.dataFormatter.deserialize(data) as any,
          meta: data.meta,
        });
      });
    });
  }

  learner(id) {
    return this._get(`/customers/${id}`, {});
  }

  emailLogs(id) {
    return this._get(`/customers/${id}/email_logs`, {});
  }

  learnerGroup(id) {
    return this._get(`/learner_groups/${id}`, {});
  }

  fetchLearnerGroupMeta() {
    return this._get(`/learner_groups/meta`, {}, true, true, true);
  }

  learningSet(id) {
    return this._get(`/learning_set/${id}`, {});
  }

  createLearningSet(name) {
    return this._post(`/learning_set`, {}, { display_name: name });
  }

  updateDynamicConditions(id, conditions) {
    return this._put(`/learner_groups/${id}/conditions`, {}, { conditions });
  }

  fetchMatchingMembers(id) {
    return this._get(
      `/learner_groups/${id}/matching_members`,
      {},
      true,
      true,
      true
    ).then((data) => {
      return new Promise((resolve, reject) => {
        resolve({
          data: this.dataFormatter.deserialize(data) as any,
          meta: data.meta,
        });
      });
    });
  }

  assignLearningSet(id, learningSetId) {
    return this._put(
      `/learner_groups/${id}/learning_set`,
      {},
      { learning_set: learningSetId }
    );
  }

  addRecommendedVideoToLearningSet(id, recommendedVideos) {
    return this._put(
      `/learning_set/${id}/recommended_videos`,
      {},
      { recommended_videos: recommendedVideos }
    );
  }

  addLearningPathToLearningSet(id, learningPaths) {
    return this._put(
      `/learning_set/${id}/collections`,
      {},
      { collections: learningPaths }
    );
  }

  addMemberToGroup(id, memberId) {
    return this._put(
      `/learner_groups/${id}/add_members`,
      {},
      { members_to_add: [memberId] }
    );
  }

  removeMemberFromGroup(id, memberId) {
    return this._put(
      `/learner_groups/${id}/remove_members`,
      {},
      { members_to_remove: [memberId] }
    );
  }

  addManagerToGroup(id, memberId) {
    return this._put(
      `/learner_groups/${id}/add_managers`,
      {},
      { managers_to_add: [memberId] }
    );
  }

  removeManagerFromGroup(id, memberId) {
    return this._put(
      `/learner_groups/${id}/remove_managers`,
      {},
      { managers_to_remove: [memberId] }
    );
  }

  learnerGroups() {
    return this._get(`/learner_groups`, {});
  }

  invite(id, isAcademy = false) {
    return this._put(
      `/customers/${id}/invite`,
      {},
      {
        medium: isAcademy ? 'academy' : '',
      }
    );
  }

  markEmailAsValid(id) {
    return this._put(`/customers/${id}/mark_email_as_valid`, {});
  }

  reEnrollCourse(courseId, customerId, canSendEmail) {
    return this._put(`/customers/${customerId}/re_enroll_course`,
      {},
      {
        course_id: courseId,
        can_send_email: canSendEmail
      },
    )
  }

  activities(id) {
    return this._get(`/customers/${id}/activities`, {});
  }

  engagedFlows(id) {
    return this._get(`/customers/${id}/engaged_flows`, {});
  }

  pendingTasks(id) {
    return this._get(`/customers/${id}/pending_tasks`, {});
  }

  reviewTaskAttempt(attemptId, data) {
    return this._post(
      `/lesson_tasks/${attemptId}/add_review`,
      {},
      { ...data },
      true
    );
  }

  courseEnrolments(id) {
    return this._get(`/customers/${id}/course_enrolments`, {});
  }

  courseAssignments(id) {
    return this._get(`/customers/${id}/course_assignments`, {});
  }

  assignCoursesToCustomer(customerId, courseIds) {
    return this._post(
      `/customers/${customerId}/course_assignments`,
      {},
      {
        course_ids: courseIds,
      },
      true,
      true
    );
  }

  removeCourseAssignmentFromCustomer(customerId, assignmentId) {
    return this._delete(
      `/customers/${customerId}/course_assignments/${assignmentId}`,
      {},
      {},
      true,
      false
    );
  }

  lgCourseAssignments(id) {
    return this._get(`/learner_groups/${id}/course_assignments`, {});
  }

  assignCoursesToLg(groupId, courseIds) {
    return this._post(
      `/learner_groups/${groupId}/course_assignments`,
      {},
      {
        course_ids: courseIds,
      },
      true,
      true
    );
  }

  updateAcademyBehavior(customerId, { includeCoursesFromLg }) {
    return this._put(
      `/customers/${customerId}/academy_behavior`,
      {},
      {
        include_from_lg: includeCoursesFromLg,
      }
    );
  }

  updateGuideSettings(guideId, data) {
    return this._put(
      `/guides/${guideId}/update_settings`,
      {},
      { ...data }
    )
  }

  addClipToGuide(guideId, siblingId, secondAt, videoKey) {
    return this._post(
      `/guides/${guideId}/add_clip`,
      {},
      {
        recording_type: "image_from_video",
        sibling_id: siblingId,
        second_at: secondAt,
        video_key: videoKey
      },
      true,
      true
    )
  }

  removeCourseAssignmentFromLg(groupId, assignmentId) {
    return this._delete(
      `/learner_groups/${groupId}/course_assignments/${assignmentId}`,
      {},
      {},
      true,
      false
    );
  }

  createLearner(form) {
    return this._post(`/customers`, {}, form);
  }

  createLearnerGroup(displayName, groupType) {
    return this._post(
      `/learner_groups`,
      {},
      { display_name: displayName, group_type: groupType }
    );
  }

  editLearnerGroup(id, displayName?, groupType?) {
    return this._put(
      `/learner_groups/${id}`,
      {},
      { display_name: displayName, group_type: groupType }
    );
  }

  deleteLearnerGroup(id) {
    return this._delete(`/learner_groups/${id}`, {});
  }

  searchLearner(search) {
    return this._get(
      `/customers/search_by_query?query=${search}`,
      {},
      true,
      true,
      true
    );
  }
  approvalStatus(id) {
    return this._get(`/customers/${id}/access_provision`, {});
  }
  verifyLearner(id, state) {
    return this._put(
      `/customers/${id}/access_provision`,
      {},
      { access: state }
    );
  }

  downloadLearnersSample() {
    return this._get(`/customers/sample_csv`, {}, true, true, true);
  }

  downloadLearnerResponse(quizAttemptId) {
    return this._get(`/quiz_attempts/${quizAttemptId}/responses`, {}, true);
  }

  exportEmailNotificationLog() {
    return this._get(`/email_notification_logs/export`, {}, true);
  }

  uploadCsv(file, inviteToAcademy, inviteToHub) {
    const formData = new FormData();
    formData.append('csv_file', file);
    formData.append('academy_invite', inviteToAcademy);
    formData.append('hub_invite', inviteToHub);
    return this._post(`/tasks/customers`, {}, formData);
  }

  uploadttsCsv(file, videoId) {
    const formData = new FormData();
    formData.append('csv_file', file);
    return this._put(`/videos/${videoId}/upload_bulk_tts`, {}, formData);
  }

  uploadArticleImage(articleId, image) {
    const formData = new FormData();
    formData.append('image', image);
    return this._post(`/articles/${articleId}/images`, {}, formData);
  }

  uploadArticleImageUrl(articleId, url) {
    return this._post(
      `/articles/${articleId}/images/url`,
      {},
      { image_url: url }
    );
  }

  addArticleVideo(articleId, videoId) {
    return this._post(
      `/articles/${articleId}/videos`,
      {},
      { video_id: videoId },
      true,
      true
    );
  }

  addSwatchColor(color, workspaceId) {
    return this._post(
      `/workspace/${workspaceId}/add_article_custom_color`,
      {},
      { color: color },
      true,
      true
    );
  }

  removeSwatchColor(color, workspaceId) {
    return this._put(
      `/workspace/${workspaceId}/remove_article_custom_color`,
      {},
      { color: color },
      true,
      true
    );
  }

  uploadLessonResources(courseId, lessonId, file) {
    const formData = new FormData();
    formData.append('assets', file);
    return this._post(
      `/courses/${courseId}/lessons/${lessonId}/assets`,
      {},
      formData,
      true
    );
  }

  deleteLessonResource(courseId, lessonId, assetId) {
    return this._delete(
      `/courses/${courseId}/lessons/${lessonId}/assets/${assetId}`,
      {}
    );
  }

  deleteArticleImage(articleId, imageId) {
    return this._delete(`/articles/${articleId}/images/${imageId}`);
  }

  deleteArticleVideo(articleId, videoId) {
    return this._delete(`/articles/${articleId}/videos/${videoId}`);
  }

  editLearner(id, form) {
    return this._put(`/customers/${id}`, {}, form);
  }

  deleteLearner(id) {
    return this._delete(`/customers/${id}`, {});
  }

  deactivateLearner(id) {
    return this._put(`/customers/${id}/deactivate`, {});
  }

  activateLearner(id) {
    return this._put(`/customers/${id}/activate`, {});
  }

  unlockLearner(id) {
    return this._put(`/customers/${id}/unlock`, {})
  }

  fetchCollections(offset = 0, type = 'walkthrough') {
    return this._get(`/collections?offset=${offset}&type=${type}`, {});
  }

  getUserInfo(skipLoginNeeded = false) {
    return this._get('/users/logged_in', {}).then((data) => {
      if (data.loginNeeded && !skipLoginNeeded) {
        this.authService.logout();
      } else {
        return data;
      }
    });
  }

  getEngagementToken() {
    return this._get('/users/engagement_token', {}, true, true, true);
  }

  getVideoInformation(videoId: string, isMobile: boolean) {
    return this._get(`/videos/${videoId}?is_mobile=${isMobile}`, {});
  }

  getMp4VideoInformation(videoId: string) {
    return this._get(`/videos/${videoId}?mp4=true`, {});
  }

  getPlaybackInformation(videoId: string) {
    return this._get(`/videos/${videoId}/playback_information`, {});
  }

  getScreenshots(screenShotId: string, skipUrl = false) {
    return this._get(
      `/video_track_screen_shots/${screenShotId}${skipUrl ? `?skip_url` : ''}`,
      {}
    );
  }

  getCluster(screenShotId, versionId) {
    return this._get(
      `/video_track_screen_shots/${screenShotId}/clusters`,
      { version_id: versionId },
      true,
      true
    );
  }

  createClusterId(screenShotId, versionId) {
    return this._post(
      `/video_track_screen_shots/${screenShotId}/clusters`,
      {},
      { version_id: versionId },
      true,
      true
    );
  }

  calculateFramePositions(
    videoId: string,
    segmentId: number,
    targetWidth: number,
    targetHeight: number
  ) {
    return this._get(
      `/videos/${videoId}/video_segments/${segmentId}/calculate_positions?target_width=${targetWidth}&target_height=${targetHeight}`,
      {},
      true,
      true
    );
  }

  updateTrackSettings(
    videoId: string,
    segmentId: number,
    currentSegment,
    newSegment?
  ) {
    return this._put(
      `/videos/${videoId}/video_segments/${segmentId}/track_settings`,
      {},
      { current: currentSegment, to_create: newSegment }
    );
  }

  updateZoomSettings(videoId: string, segmentId: number, zoomConfig) {
    return this._put(
      `/videos/${videoId}/video_segments/${segmentId}/zoom_settings`,
      {},
      zoomConfig
    );
  }

  fetchAllCords(videoId: string, targetWidth: number, targetHeight: number) {
    return this._get(
      `/videos/${videoId}/cords?target_width=${targetWidth}&target_height=${targetHeight}`,
      {},
      true,
      true
    );
  }

  updateScreenshot(videoId: string, segmentId: number, screenShotId) {
    return this._put(
      `/videos/${videoId}/video_segments/${segmentId}/screenshots`,
      {},
      { new_screenshot_id: screenShotId }
    );
  }

  updateSubtitleText(videoId: string, segmentId: number, language, text) {
    return this._put(
      `/videos/${videoId}/video_segments/${segmentId}/subtitles`,
      {},
      { language, text }
    );
  }

  updateRelativeCtaLinks(
    videoId: string,
    segmentId: number,
    label,
    value,
    endSlide
  ) {
    return this._put(
      `/videos/${videoId}/video_segments/${segmentId}/relative_cta_links`,
      {},
      { label, value, endSlide }
    );
  }

  updateChapters(videoId: string, segmentId: number, label) {
    return this._put(
      `/videos/${videoId}/video_segments/${segmentId}/chapter_label`,
      {},
      { value: label }
    );
  }

  updateQuickJumpSettings(videoId: string, quickJump) {
    return this._put(
      `/videos/${videoId}/quick_jump`,
      {},
      { value: quickJump },
      true,
      true
    );
  }

  updatePlaybackSpeedSettings(videoId: string, playbackSpeed) {
    return this._put(
      `/videos/${videoId}/playback_speed`,
      {},
      { value: playbackSpeed },
      true,
      true
    );
  }

  changeVideoVoice(videoId: string, voice, narrationType) {
    return this._post(
      `/videos/${videoId}/change_voice`,
      {},
      { to_translate_voice: voice, narration_type: narrationType },
      true,
      false
    );
  }

  translateText(text: string, src: string, dest: string) {
    return this._post(
      `/audio_tracks/translate`,
      {},
      { text, src, dest },
      true,
      true
    );
  }

  insertSiblingImageTrackId(
    videoId: string,
    segmentId: number,
    trackId,
    insertBefore = false,
    taskId
  ) {
    const formData = new FormData();
    formData.append('insert_before', insertBefore ? 'true' : 'false');
    formData.append('image_track_id', trackId);
    if (taskId) {
      formData.append('task_id', taskId);
    }
    return this._post(
      `/videos/${videoId}/video_segments/${segmentId}/siblings`,
      {},
      formData
    );
  }

  updateImageFragment(videoId: string, segmentId: number, trackId, taskId) {
    return this._put(
      `/videos/${videoId}/video_segments/${segmentId}/change_image`,
      {},
      {
        image_track_id: trackId,
        task_id: taskId,
      }
    );
  }

  insertSibling(
    videoId: string,
    segmentId: number,
    file,
    insertBefore = false,
    taskId?
  ) {
    const formData = new FormData();
    formData.append('image', file);

    if (taskId) {
      formData.append('task_id', taskId);
    }
    return this._post(
      `/videos/${videoId}/video_segments/${segmentId}/siblings`,
      {},
      formData
    );
  }

  insertSnippets(videoId: string, segmentId, snippets) {
    return this._put(
      `/videos/${videoId}/video_segments/${segmentId}/snippets`,
      {},
      { snippets }
    );
  }

  editSnippet(videoId: string, segmentId, snippet, snippets) {
    return this._put(
      `/videos/${videoId}/video_segments/${segmentId}/update_snippet`,
      {},
      { snippet, to_be_added: snippets }
    );
  }

  insertBlocks(videoId: string, segmentId, srcVideoId) {
    return this._put(
      `/videos/${videoId}/video_segments/${segmentId}/import_blocks`,
      {},
      { src_video_id: srcVideoId }
    );
  }

  insertNarrationSibling(videoId: string, segmentId: number) {
    return this._post(
      `/videos/${videoId}/video_segments/${segmentId}/narration_siblings`,
      {},
      {}
    );
  }

  insertVideoSibling(videoId: string, segmentId: number, file) {
    const formData = new FormData();
    formData.append('file', file);
    return this._post(
      `/videos/${videoId}/video_segments/${segmentId}/video_siblings`,
      {},
      formData
    );
  }

  insertVideoRecording(videoId: string, file) {
    const formData = new FormData();
    formData.append('file', file);
    return this._post(
      `/videos/${videoId}/video_tracks/screen_recordings`,
      {},
      formData
    );
  }

  cloneSibling(videoId: string, segmentId: number) {
    return this._post(
      `/videos/${videoId}/video_segments/${segmentId}/clone_sibling`,
      {},
      {}
    );
  }

  deleteSibling(videoId: string, segmentId: number) {
    return this._delete(
      `/videos/${videoId}/video_segments/${segmentId}`,
      {},
      {},
      true,
      false
    );
  }

  deleteMultipleClips(
    videoId: string,
    versionId: number,
    segmentIds: number[]
  ) {
    return this._put(
      `/videos/${videoId}/versions/${versionId}/delete_clips`,
      {},
      {
        clips_to_remove: segmentIds,
      },
      true,
      false
    );
  }

  updateSegmentAdvanced(videoId: string, segmentId: number, advancedParams) {
    return this._put(
      `/videos/${videoId}/video_segments/${segmentId}/advanced_ordinates`,
      {},
      advancedParams
    );
  }

  copyToAllSegments(
    videoId: string,
    segmentId: number,
    property: string,
    copyAll = true
  ) {
    return this._put(
      `/videos/${videoId}/video_segments/${segmentId}/copy_to_all`,
      {},
      { property, copy_all: copyAll }
    );
  }

  updateBgImage(videoId, segmentId, file, templateId?) {
    const formData = new FormData();
    if (file) {
      formData.append('file', file);
    } else {
      formData.append('template_id', templateId);
    }

    return this._put(
      `/videos/${videoId}/video_segments/${segmentId}/bg_image`,
      {},
      formData
    );
  }

  mergeClips(
    videoId: string,
    versionId: number,
    startId: number,
    endId: number
  ) {
    return this._put(
      `/videos/${videoId}/versions/${versionId}/merge_clips`,
      {},
      { start: startId, end: endId }
    );
  }

  deleteIntro(videoId: string, versionId: number) {
    return this._delete(
      `/videos/${videoId}/versions/${versionId}/intro_slide`,
      {},
      {},
      true,
      false
    );
  }

  getImageTracks(id: string) {
    return this._get(`/image_tracks/${id}`, {});
  }

  insertImageTrack(videoId: string, blob, skipAccess = false) {
    const formData = new FormData();
    formData.append('blob', blob);

    return this._post(
      `/videos/${videoId}/image_tracks?skip_access_url=${skipAccess}`,
      { 'Content-Type': 'application/x-www-form-urlencoded' },
      formData
    );
  }

  introVideoData(videoId: string, file) {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('screenshot', true);

    return this._post(
      `/videos/${videoId}/video_tracks/intro_video`,
      { 'Content-Type': 'application/x-www-form-urlencoded' },
      formData
    );
  }

  getPlayerLink(videoId: string) {
    return this._get(`/videos/${videoId}/play_link`, {}, true, true);
  }

  getThumbnailUrl(videoId: string) {
    return this._get(`/videos/${videoId}/thumbnail_url`, {}, true, true);
  }

  getShareableGif(videoId: string) {
    return this._get(`/videos/${videoId}/thumbnail_gif`, {}, true, true);
  }

  getLinkAccessors(shareableLinkId: string) {
    return this._get(`/shareable_links/${shareableLinkId}/link_accessors`, {});
  }

  addRestrictionLink(shareableLinkId: string, type, resourceId: string) {
    return this._post(
      `/shareable_links/${shareableLinkId}/restrictions`,
      {},
      {
        resource_type: type,
        resource_id: resourceId,
      }
    );
  }

  removeRestrictionLink(shareableLinkId: string, type, resourceId: string) {
    return this._delete(
      `/shareable_links/${shareableLinkId}/restrictions`,
      {},
      {
        resource_type: type,
        resource_id: resourceId,
      },
      true,
      false
    );
  }

  updateAccessControl(shareableLinkId: string, accessControl) {
    return this._put(
      `/shareable_links/${shareableLinkId}/access_control`,
      {},
      { access_control: accessControl },
      true,
      true,
      true
    );
  }

  fetchActiveConversionProcess(videoId: string) {
    return this._get(`/videos/${videoId}/active_conversion_process`, {});
  }

  fetchYouTubeVersions(videoId: string) {
    return this._get(`/videos/${videoId}/youtube_versions`, {});
  }

  uploadToYouTube(videoId: string, privacyStatus) {
    return this._post(
      `/videos/${videoId}/youtube_versions`,
      {},
      { privacy_status: privacyStatus }
    );
  }

  cancelYoutubePublishing(videoId: string, versionId: string) {
    return this._put(`/videos/${videoId}/youtube_versions/${versionId}`, {});
  }

  feedbackAnalytics(videoId: string) {
    return this._get(`/analytics/${videoId}/feedback`, {}, true, true);
  }

  articleFeedbackAnalytics(videoId: string) {
    return this._get(`/analytics/${videoId}/article_feedback`, {}, true, true);
  }

  getCollectionThumbnailUrl(collectionId: string) {
    return this._get(
      `/collections/${collectionId}/thumbnail_url`,
      {},
      true,
      true
    );
  }

  fetchScreenRecordings(videoId) {
    return this._get(`/videos/${videoId}/video_tracks/screen_recordings`, {});
  }

  collectionWithGroupReordering(collectionId, ids) {
    return this._put(
      `/collections/${collectionId}/update_order`,
      {},
      {
        video_ids: ids,
      }
    );
  }

  collectionReordering(collectionId, ids) {
    return this._put(
      `/collections/${collectionId}/reorder`,
      {},
      {
        content_ids: ids,
      }
    );
  }

  collectionContentReordering(collectionId, ids, sectionId) {
    return this._put(
      `/collections/${collectionId}/with_sections/${sectionId}/update_order`,
      {},
      {
        content_ids: ids,
      }
    );
  }

  chaptersReordering(courseId, chapterIds) {
    return this._put(
      `/courses/${courseId}/chapters_order`,
      {},
      {
        chapter_ids: chapterIds,
      }
    );
  }

  categoriesReordering(interalName, categoriesIds, language) {
    return this._put(
      `/academy/pages/${interalName}/categories_order`,
      {},
      {
        ids: categoriesIds,
        language,
      }
    );
  }

  updateCollectionSectionOrder(collectionId: string, sectionIds: any[]) {
    return this._put(
      `/collections/${collectionId}/with_sections/update_section_order`,
      {},
      { section_ids: sectionIds }
    );
  }

  updateThumbnailUrl(id, type, image) {
    const formData = new FormData();
    formData.append('resource_type', type);
    formData.append('resource_id', id);
    formData.append('blob', image);
    return this._post(`/thumbnail_images`, {}, formData, true, true);
  }

  getBgUrl(videoId: string, bgScore: string) {
    return this._get(
      `/videos/${videoId}/bg_url?bg_score=${bgScore}`,
      {},
      true,
      true
    );
  }

  getBgImages(videoId: string) {
    return this._get(`/videos/${videoId}/bg_images`, {}, true, true);
  }

  updateIntroVideoSettings(
    videoId: string,
    { narrationType, narrationVoiceId }
  ) {
    return this._put(
      `/videos/${videoId}/video_settings`,
      {},
      {
        narration_voice_id: narrationVoiceId,
        narration_type: narrationType,
      },
      true,
      true,
      true
    );
  }

  generateAudioForText(
    text,
    narrationVoiceId,
    narrationVoiceStyle,
    options = {}
  ) {
    return this._post(
      `/audio_tracks/quick`,
      {},
      Object.assign(
        {
          text,
          narration_id: narrationVoiceId,
          voice_style: narrationVoiceStyle,
        },
        options
      ),
      true,
      true,
      true
    );
  }

  customPronunciations() {
    return this._get(`/custom_pronunciations`, {}, true, true);
  }

  createPronunciation(word, ipa) {
    return this._post('/custom_pronunciations', {}, { word, ipa }, true, true);
  }

  deletePronunciation(word) {
    return this._delete('/custom_pronunciations', {}, { word }, true, true);
  }

  ipaSuggestions(word) {
    return this._get(
      `/custom_pronunciations/suggest?word=${word}`,
      {},
      true,
      true
    );
  }

  listenIpa(options) {
    return this._post(`/custom_pronunciations/listen`, {}, options, true, true);
  }

  updateSegment(videoId: string, segmentId: number, { key, value }) {
    return this._put(
      `/videos/${videoId}/video_segments/${segmentId}`,
      {},
      {
        key,
        value,
      }
    );
  }

  reorderSegment(videoId: string, segmentId: number, nextTo) {
    return this._put(
      `/videos/${videoId}/video_segments/${segmentId}`,
      {},
      {
        key: 'reorder_clip',
        value: nextTo,
      }
    );
  }

  insertVideoNarrationTrack(videoId: string, multipartId: number, helpNotes) {
    return this._post(
      `/videos/${videoId}/video_tracks`,
      {},
      { multipart_id: multipartId, help_notes: helpNotes }
    );
  }

  initializeUpload(videoId: string, mimeType: string) {
    return this._post(
      `/videos/${videoId}/multipart_uploads`,
      {},
      { mime_type: mimeType },
      true,
      true,
      true
    );
  }

  uploadPartStream(
    multipartId: string,
    blob: Blob,
    partNumber: number,
    cancelToken: any
  ) {
    const formData = new FormData();
    formData.append('blob', blob);
    formData.append('part_number', '' + partNumber);
    return this._put(
      `/multipart_uploads/${multipartId}`,
      { 'Content-Type': 'application/x-www-form-urlencoded' },
      formData,
      true,
      true,
      cancelToken
    );
  }

  createGuideFromVideo(videoId, folderId) {
    return this._post(
      '/guides',
      { 'Content-Type': 'application/json' },
      {
        src_video_id: videoId,
        folder_id: folderId
      }, true,
      true);
  }

  createGuide(multiPartIds, smartCaptureInfo, title, folderId, language) {
    return this._post(
      '/guides',
      { 'Content-Type': 'application/json' },
      {
        multipart_ids: multiPartIds,
        smart_capture_info: {
          clicks: smartCaptureInfo && smartCaptureInfo.clicks,
          total_duration: smartCaptureInfo && smartCaptureInfo.totalDuration,
          drift_duration: smartCaptureInfo && smartCaptureInfo.timeDrift,
          enabled: smartCaptureInfo && smartCaptureInfo.enabled,
        },
        language: language,
        title: title,
        folder_id: folderId
      },
      true,
      true
    );
  }

  createEmptyGuide(folderId) {
    return this._post(
      '/guides',
      { 'Content-Type': 'application/json' },
      {
        multipart_ids: [],
        folder_id: folderId
      },
      true,
      true
    );
  }

  appendGuideSteps(guideId, nextTo, multiPartIds, smartCaptureInfo) {
    return this._put(
      `/guides/${guideId}/add_steps`,
      { 'Content-Type': 'application/json' },

      {
        next_to: nextTo,
        multipart_ids: multiPartIds,
        smart_capture_info: {
          clicks: smartCaptureInfo && smartCaptureInfo.clicks,
          total_duration: smartCaptureInfo && smartCaptureInfo.totalDuration,
          drift_duration: smartCaptureInfo && smartCaptureInfo.timeDrift,
          enabled: smartCaptureInfo && smartCaptureInfo.enabled,
        },
      },
      true,
      true
    );
  }

  updateGuide(id, title, description) {
    return this._put(
      `/guides/${id}/edit`,
      {},
      {
        title,
        description
      },
    );
  }

  guideAccessControl(shareableLinkId: string, accessControl) {
    return this._put(
      `/guides/shareable_links/${shareableLinkId}/access_control`,
      {},
      {
        access_control: accessControl
      },
      true,
      true,
      true
    )
  }

  quickGuideUpdate(guideId: string, title?) {
    return this._put(
      `/guides/${guideId}/quick_update`,
      {},
      { title },
      true,
      true,
      true
    );
  }

  guideAsPdf(id) {
    return this._get(`/guides/${id}/pdf`, {}, true, true)
  }

  getGuide(id) {
    return this._get(`/guides/${id}`, {})
  }

  getGuides(offset, limit) {
    return this._get(`/guides?&offset=${offset}&limit=${limit}`, {}, true);
  }

  getDocuments(offset, limit) {
    return this._get(`/documents?&offset=${offset}&limit=${limit}`, {}, true);
  }

  getPublishedGuide(id) {
    return this._get(`/guides/${id}/published_version`, {})
  }

  getGuideContent(id, guideContentId, isAssessmentMode = false) {
    return this._get(`/guides/${id}/contents/${guideContentId}?assessment_mode=${isAssessmentMode}`, {})
  }

  updateGuideStep(id, stepId, title, description) {
    return this._put(
      `/guides/${id}/steps/${stepId}/edit`,
      {},
      {
        title,
        description
      },
    );
  }

  guideClone(id, translate, language, folderId) {
    return this._post(`/guides/${id}/clone`,
      {},
      {
        translate,
        language,
        folder_id: folderId
      },
    );
  }

  upsertImage(id, stepId, file) {
    const formData = new FormData();
    formData.append('file', file);
    return this._post(
      `/guides/${id}/steps/${stepId}/content`,
      { 'Content-Type': 'application/x-www-form-urlencoded' },
      formData,
    );
  }

  cloneGuideStep(id, stepId) {
    return this._post(
      `/guides/${id}/steps/${stepId}/clone`,
      {},
      {},
    );
  }


  reorderStep(id, stepId, siblingId) {
    return this._put(
      `/guides/${id}/steps/${stepId}/reorder`,
      {},
      {
        sibling_id: siblingId
      },
    );
  }

  deleteGuideStep(id, stepId) {
    return this._delete(
      `/guides/${id}/steps/${stepId}`,
      {},
      {},
      undefined,
      false
    );
  }

  addCtaGuideStep(id, stepId, label, url) {
    return this._put(
      `/guides/${id}/steps/${stepId}/cta`,
      {},
      {
        label,
        value: url
      }
    )
  }

  removeCtaGuideStep(id, stepId) {
    return this._put(`/guides/${id}/steps/${stepId}/remove_cta`,
      {},
      {}
    )
  }

  updateStepAdvanced(guideId: string, stepId: number, advancedParams) {
    return this._put(
      `/guides/${guideId}/steps/${stepId}/advanced_edits`,
      {},
      advancedParams
    );
  }

  updateStepBlurOrdinates(guideId: string, stepId: number, parameters) {
    return this._put(
      `/guides/${guideId}/steps/${stepId}/blur`,
      {},
      parameters
    );
  }

  updateStepZoomValues(guideId: string, stepId: number, parameters) {
    return this._put(
      `/guides/${guideId}/steps/${stepId}/zoom`,
      {},
      parameters
    );
  }

  updateCropOrdinates(guideId: string, stepId: number, parameters) {
    return this._put(
      `/guides/${guideId}/steps/${stepId}/crop`,
      {},
      parameters
    );
  }

  addStep(guideId: string, siblingId) {
    return this._post(`/guides/${guideId}/steps`, {}, { sibling_id: siblingId })
  }

  publishGuide(guideId: string) {
    return this._put(`/guides/${guideId}/publish`, {})
  }

  deleteGuide(guideId: string) {
    return this._delete(`/guides/${guideId}`, {}, {}, true, true)
  }

  createGuideDraft(guideId: string) {
    return this._post(`/guides/${guideId}/draft`, {})
  }

  createVideoV2({
    multiPartIds,
    recordingType,
    additionalInfo,
    smartCaptureInfo,
    clipsToCreate,
    zoomEnabled,
    spotlightEnabled,
    disableClipSplitting,
    autoTts,
    folderId,
    newExtension,
    language
  }) {
    return this._post(
      '/videos',
      { 'Content-Type': 'application/json' },
      {
        v2: true,
        multipart_ids: multiPartIds,
        recording_type: recordingType,
        smart_capture_info: {
          clicks: smartCaptureInfo && smartCaptureInfo.clicks,
          total_duration: smartCaptureInfo && smartCaptureInfo.totalDuration,
          drift_duration: smartCaptureInfo && smartCaptureInfo.timeDrift,
          enabled: smartCaptureInfo && smartCaptureInfo.enabled,
          auto_tts: autoTts,
          add_zoom: zoomEnabled,
          add_spotlight: spotlightEnabled,
          disable_clip_splitting: disableClipSplitting,
          new_extension: newExtension
        },
        clips_to_create: clipsToCreate,
        title: additionalInfo && additionalInfo.title,
        folder_id: folderId,
        language: language,
      },
      true,
      true
    );
  }

  appendClipV2(
    videoId,
    nextTo,
    {
      multiPartIds,
      recordingType,
      smartCaptureInfo,
      clipsToCreate,
      zoomEnabled,
      spotlightEnabled,
      disableClipSplitting,
      autoTts,
    }
  ) {
    return this._post(
      `/videos/${videoId}/add_clip`,
      { 'Content-Type': 'application/json' },
      {
        v2: true,
        multipart_ids: multiPartIds,
        recording_type: recordingType,
        next_to: nextTo,
        smart_capture_info: {
          clicks: smartCaptureInfo && smartCaptureInfo.clicks,
          total_duration: smartCaptureInfo && smartCaptureInfo.totalDuration,
          add_zoom: zoomEnabled,
          add_spotlight: spotlightEnabled,
          disable_clip_splitting: disableClipSplitting,
          auto_tts: autoTts,
        },
        clips_to_create: clipsToCreate,
      },
      true,
      true
    );
  }

  uploadFileV2(type, file, folderId) {
    const formData = new FormData();
    formData.append('recording_type', type);
    formData.append('file', file);
    formData.append('v2', 'true');
    if (folderId) {
      formData.append('folder_id', folderId);
    }
    return this._post(
      `/videos`,
      { 'Content-Type': 'application/x-www-form-urlencoded' },
      formData,
      true,
      true,
      true
    );
  }

  uploadIdV2(type, file, _additionalInfo) {
    const data = {
      recording_type: type,
      track_id: id,
      v2: true,
    };

    return this._post(
      `/videos`,
      { 'Content-Type': 'application/json' },
      data,
      true,
      true,
      true
    );
  }

  appendClipFromFileV2(videoId, type, file, nextTo) {
    const formData = new FormData();
    formData.append('recording_type', type);
    formData.append('file', file);
    formData.append('next_to', nextTo);
    formData.append('v2', 'true');
    return this._post(
      `/videos/${videoId}/add_clip`,
      { 'Content-Type': 'application/x-www-form-urlencoded' },
      formData,
      true,
      true,
      true
    );
  }

  appendClipFromIdV2(videoId, type, id, nextTo, clips_to_create = null, clipSplitMode = null) {
    const data = {
      recording_type: type,
      track_id: id,
      next_to: nextTo,
      v2: true,
      clips_to_create,
    };
    if (clipSplitMode == 'split_only') {
      data['smart_capture_info'] = {
        generate_clips: true
      }
    }
    else if (clipSplitMode == 'split_and_tts') {
      data['smart_capture_info'] = {
        generate_clips: true,
        auto_tts: true
      }
    }
    return this._post(
      `/videos/${videoId}/add_clip`,
      { 'Content-Type': 'application/json' },
      data,
      true,
      true,
      true
    );
  }

  uploadPart(multipartId: string, blob: Blob, partNumber: number) {
    const formData = new FormData();
    formData.append('blob', blob);
    formData.append('part_number', '' + partNumber);
    return this._put(
      `/multipart_uploads/${multipartId}`,
      { 'Content-Type': 'application/x-www-form-urlencoded' },
      formData,
      true,
      true,
      true
    );
  }

  uploadSinglePart(file, additionalInfo) {
    const formData = new FormData();
    formData.append('file', file);
    return this._post(
      `/multipart_uploads/single_part`,
      { 'Content-Type': 'application/x-www-form-urlencoded' },
      formData,
      true,
      true,
      true
    );
  }

  completePart(multipartId: string) {
    return this._put(
      `/multipart_uploads/${multipartId}/complete`,
      {},
      {},
      true,
      true,
      true
    );
  }

  discard(multipartId: string) {
    return this._delete(
      `/multipart_uploads/${multipartId}`,
      {},
      {},
      true,
      true,
      true
    );
  }

  updateVideoNarrationInSegment(
    videoId: string,
    segmentId: number,
    audioTrackId,
    videoTrackId
  ) {
    return this._put(
      `/videos/${videoId}/video_segments/${segmentId}/narrations`,
      {},
      {
        audio_track_id: audioTrackId,
        video_track_id: videoTrackId,
      }
    );
  }

  updatePipNarration(
    videoId: string,
    segmentId: number,
    audioTrackId,
    videoTrackId,
    ordinates = {}
  ) {
    return this._put(
      `/videos/${videoId}/video_segments/${segmentId}/narrations_pip`,
      {},
      {
        audio_track_id: audioTrackId,
        video_track_id: videoTrackId,
        ordinates,
      }
    );
  }

  updateOrdinates(
    videoId: string,
    segmentId: number,
    ordinates = {},
    fill?,
    fontColor?
  ) {
    return this._put(
      `/videos/${videoId}/video_segments/${segmentId}/ordinates`,
      {},
      { ordinates, bg_color: fill, font_color: fontColor }
    );
  }

  updateBlurOrdinates(videoId: string, segmentId: number, ordinates = {}) {
    return this._put(
      `/videos/${videoId}/video_segments/${segmentId}/blur`,
      {},
      { ordinates }
    );
  }

  updateVersionTitle(videoId: string, versionId: number, title) {
    return this._put(`/videos/${videoId}/versions/${versionId}`, {}, { title });
  }

  updateVersionResolution(videoId: string, versionId: number, size) {
    return this._put(`/videos/${videoId}/versions/${versionId}`, {}, size);
  }

  updateRecentlyUsed(videoId: string, versionId: number, key, value) {
    return this._put(
      `/videos/${videoId}/versions/${versionId}/recently_used`,
      {},
      { key, value },
      true,
      true
    );
  }

  updatePreferences(videoId: string, versionId: number, preferences) {
    return this._put(
      `/videos/${videoId}/versions/${versionId}/preferences`,
      {},
      preferences
    );
  }

  updateVolumeSettings(
    videoId: string,
    versionId: number,
    audioTrackVolume,
    bgTrackVolume
  ) {
    return this._put(
      `/videos/${videoId}/versions/${versionId}/volume_settings`,
      {},
      {
        audio_track_volume: audioTrackVolume,
        bg_track_volume: bgTrackVolume,
      }
    );
  }

  quickUpdate(videoId: string, title?, description?) {
    return this._put(
      `/videos/${videoId}/quick_update`,
      {},
      { title, description },
      true,
      true,
      true
    );
  }

  regenerateDescription(videoId: string, conversionProcessId) {
    return this._put(`/videos/${videoId}/regenerate_description/${conversionProcessId}`);
  }

  updateVersionDescription(videoId: string, versionId: number, description) {
    return this._put(
      `/videos/${videoId}/versions/${versionId}`,
      {},
      { description }
    );
  }

  updateVersionSettings(
    videoId: string,
    versionId: number,
    bgEnabled?,
    bgScore?,
    addWatermark?,
    configuredSubtitles?,
    overrideIntro?
  ) {
    return this._put(
      `/videos/${videoId}/versions/${versionId}`,
      {},
      {
        bg_enabled: bgEnabled,
        bg_score: bgScore,
        add_watermark: addWatermark,
        configured_subtitles: configuredSubtitles,
        override_intro: overrideIntro,
      }
    );
  }

  updateRecordingPreferences(preferences) {
    return this._put(
      `/users/recording_preferences`,
      {},
      preferences,
      false,
      true
    );
  }

  deleteVideoTrack(videoId: string, versionId: number, trackId) {
    return this._put(
      `/videos/${videoId}/versions/${versionId}`,
      {},
      { remove_track_id: trackId }
    );
  }

  workspaceSubscriptions() {
    // return this._get(`/analytics/overall_data`, {});
    return this._get(`/workspace_subscriptions/`, {}, true, true);
  }

  currentUsage() {
    return this._get(`/workspace_subscriptions/current_usage`, {}, true, true);
  }

  workspaceCheckoutLink(planId?, addonIds?) {
    return this._post(
      `/workspace_subscriptions/checkout_link`,
      {},
      { plan_id: planId, addon_ids: addonIds },
      true,
      true
    );
  }

  subscriptionEstimate(action) {
    return this._post(
      `/workspace_subscriptions/estimates`,
      {},
      { action_type: action },
      true,
      true
    );
  }

  updateWorkspaceSubscription() {
    return this._put(`/workspace_subscriptions`, {}, {}, true, true);
  }

  setPortalSession() {
    return this._post(
      '/workspace_subscriptions/portal_sessions',
      {},
      {},
      true,
      true
    );
  }

  prePublish(videoId: string) {
    return this._post(`/videos/${videoId}/pre_publish`, {}, {});
  }

  onboard(videoId: string) {
    return this._put(`/videos/${videoId}/on_board`, {}, {}, true, true);
  }

  discardDraft(videoId: string) {
    return this._put(
      `/videos/${videoId}/discard_draft`,
      {},
      {},
      true,
      true,
      true
    );
  }

  createDraft(videoId: string) {
    return this._post(`/videos/${videoId}/draft`, {}, {}, true, true, true);
  }

  rollbackVideo(videoId: string) {
    return this._post(`/videos/${videoId}/rollback`, {}, {});
  }

  migrateToV2(videoId: string) {
    return this._put(`/videos/${videoId}/migrate_to_v2`, {}, {}, true, true, true);
  }

  publish(videoId: string) {
    return this._put(`/videos/${videoId}/publish`, {}, {});
  }

  getAudioTrackInformation(audioTrackId: string, skipUrl = false) {
    return this._get(
      `/audio_tracks/${audioTrackId}?skip_access_url=${skipUrl}`,
      {}
    );
  }

  getImageTrackInformation(imageTrackId: string) {
    return this._get(`/image_tracks/${imageTrackId}`, {});
  }

  insertAudioTrackFor(
    videoId,
    text,
    narrationVoiceId,
    narrationVoiceStyle,
    pitch,
    speed,
    volume,
    segmentId?
  ) {
    return this._post(
      `/videos/${videoId}/audio_tracks`,
      {},
      {
        text,
        narration_voice_id: narrationVoiceId,
        narration_voice_style: narrationVoiceStyle,
        pitch,
        speed,
        volume,
        segment_id: segmentId
      }
    );
  }

  ttsWithClonedvoice(videoId, text) {
    return this._post(
      `/videos/${videoId}/audio_tracks/tts_via_cloned_voice`,
      {},
      {
        text
      }
    );
  }

  insertAudioTrackForBlob(videoId, blob, contentType, isUpload) {
    const formData = new FormData();
    formData.append('narration', blob);
    formData.append('mime_type', contentType);
    formData.append('is_upload', isUpload);
    return this._post(
      `/videos/${videoId}/audio_tracks`,
      { 'Content-Type': 'application/x-www-form-urlencoded' },
      formData
    );
  }

  insertBgScore(videoId, blob, contentType) {
    const formData = new FormData();
    formData.append('bg_score', blob);
    formData.append('mime_type', contentType);

    return this._post(
      `/videos/${videoId}/audio_tracks`,
      { 'Content-Type': 'application/x-www-form-urlencoded' },
      formData
    );
  }

  saveSpeakerNotes(videoId, segmentId, speakerNotes) {
    return this._put(
      `/videos/${videoId}/video_segments/${segmentId}/speaker_notes`,
      {},
      {
        notes: speakerNotes,
      }
    );
  }
  getUserNotesSuggestions(text, videoId) {
    return this._post(`/assist_user_notes/${videoId}`, {},
      {
        input_text: text
      },
      true
    );
  }

  triggerActivityDownload(instanceId) {
    return this._post(
      `/video_hub_settings/analytics/trigger_download?instance_id=${instanceId}`,
      {},
      {},
      true,
      true,
      true
    );
  }

  getTrackInformation(videoTrackId: string, skipUrl = false) {
    return this._get(
      `/video_tracks/${videoTrackId}?skip_access_url=${skipUrl}`,
      {}
    );
  }

  getMergeUrlTrackInformation(videoTrackId: string) {
    return this._get(`/video_tracks/${videoTrackId}?merge_url=${true}`, {});
  }

  getShareableLinkInfo(shareableLinkId: string) {
    return this._get(
      `/shareable_links/${shareableLinkId}`,
      {},
      true,
      false,
      false
    );
  }

  getCollection(collectionId: string) {
    return this._get(`/collections/${collectionId}`, {});
  }

  deleteCollection(collectionId: string) {
    return this._delete(`/collections/${collectionId}`, {});
  }

  deleteVideo(videoId: string) {
    return this._delete(`/videos/${videoId}`, {});
  }

  restoreVideo(videoId: string) {
    return this._post(`/videos/${videoId}/restore`, {}, {});
  }

  restoreFolder(videoId: string) {
    return this._post(`/folders/${videoId}/restore`, {}, {}, true, false, true);
  }

  restore(spaceId: string, id: string, type: string) {
    return this._post(
      `/contents/restore?space_id=${spaceId}&id=${id}&type=${type}`,
      {},
      {},
      true,
      true
    ).then((data) => {
      return new Promise((resolve, reject) => {
        resolve({
          content: this.dataFormatter.deserialize(data) as any,
          meta: data.meta,
        });
      });
    });
  }

  createTask(taskName, videoId, opts = {}) {
    return this._post(
      `/tasks`,
      {},
      {
        task_name: taskName,
        context: {
          video_id: videoId,
          ...opts,
        },
      }
    );
  }

  fetchTasks(taskId) {
    return this._get(`/tasks/${taskId}`, {});
  }

  createCollection(title, videoIds = null, articleIds = null, guideIds = null, documentIds, type = 'walkthrough') {
    return this._post(
      `/collections/with_sections?type=${type}`,
      {},
      {
        video_ids: videoIds,
        title: title,
        is_flow: true,
        article_ids: articleIds,
        guide_ids: guideIds,
        document_ids: documentIds
      }
    );
  }

  getWorkspaceMemberships() {
    return this._get(`/workspace_memberships`, {});
  }

  updateMembership(email, role) {
    return this._post(
      `/workspace_memberships`,
      {},
      {
        email,
        role,
      }
    );
  }

  inviteBulk(emails, role) {
    return this._post(
      `/workspace_memberships`,
      {},
      {
        emails,
        role,
      },
      true,
      true,
      true
    );
  }

  reInviteMember(id) {
    return this._put(`/workspace_memberships/${id}/re_invite`, {}, {});
  }

  revokeMemberAccess(id) {
    return this._delete(`/workspace_memberships/${id}`, {});
  }

  changeRole(id, role) {
    return this._put(`/workspace_memberships/${id}`, {}, { role });
  }

  updateDigestEmailUserConfiguration(id, value) {
    return this._put(
      `/workspace_memberships/${id}/digest_emails`,
      {},
      { send_digest_emails: value }
    );
  }

  updateDigestEmailConfiguration(daily, weekly, monthly, academyWeekly) {
    return this._put(
      `/workspaces/digest_mail`,
      {},
      { daily, weekly, monthly, academy_weekly: academyWeekly }
    );
  }

  updateCollection(
    collectionId: string,
    title?: string | null,
    videosToAdd?: any[] | null,
    videosToRemove?: any[] | null,
    description?: string | null,
    isFlow?: boolean | null,
    articlesToAdd?: any[] | null,
    articlesToRemove?: any[] | null,
    slugPath?: string | null,
    internalName?: string | null
  ) {
    return this._put(
      `/collections/${collectionId}`,
      {},
      {
        title,
        videos_to_add: videosToAdd,
        videos_to_remove: videosToRemove,
        description,
        is_flow: isFlow,
        articles_to_add: articlesToAdd,
        articles_to_remove: articlesToRemove,
        slug_path: slugPath,
        internal_name: internalName
      }
    );
  }

  addSection(
    collectionId: string,
    name?: string | null,
    videoIds?: any[] | null
  ) {
    return this._post(
      `/collections/${collectionId}/sections`,
      {},
      {
        name,
        content_ids: videoIds,
      }
    );
  }

  createSection(
    collectionId: string,
    name?: string | null,
    order?: number | null
  ) {
    return this._post(
      `/collections/${collectionId}/with_sections/create`,
      {},
      {
        name,
        order,
      }
    );
  }

  updateSection(collectionId: string, index: number, name?: string | null) {
    return this._put(
      `/collections/${collectionId}/sections`,
      {},
      {
        index,
        name,
      }
    );
  }

  updateCollectionSection(
    collectionId: string,
    sectionId: string,
    name?: string | null
  ) {
    return this._put(
      `/collections/${collectionId}/with_sections/${sectionId}`,
      {},
      {
        name,
      }
    );
  }

  deleteSection(collectionId: string, index: number) {
    return this._delete(
      `/collections/${collectionId}/sections`,
      {},
      { index },
      true,
      false,
      true
    );
  }

  deleteCollectionSection(collectionId: string, sectionId: string) {
    return this._delete(
      `/collections/${collectionId}/with_sections/${sectionId}`
    );
  }

  addSectionContent(
    collectionId: string,
    contentIds: any[],
    contentType: string,
    sectionId?: string | null
  ) {
    return this._post(
      `/collections/${collectionId}/with_sections/add_content`,
      {},
      {
        content_ids: contentIds,
        section_id: sectionId,
        content_type: contentType,
      }
    );
  }

  removeSectionContent(collectionId: string, associationId: string) {
    return this._delete(
      `/collections/${collectionId}/with_sections/remove_content`,
      {},
      { association_id: associationId },
      true,
      false,
      true
    );
  }

  moveToSection(collectionId: string, associationId: string, selectedSectionId) {
    return this._put(
      `/collections/${collectionId}/with_sections/move_content`,
      {},
      { association_id: associationId, target_section_id: selectedSectionId },
      true,
      false,
      true
    );
  }

  removeCollectionSection(collectionId: string, sectionId: string) {
    return this._delete(
      `/collections/${collectionId}/with_sections/${sectionId}`,
      {},
      {},
      true,
      false,
      true
    );
  }

  getCollectionUsageHistogram(collectionId, startDate, endDate) {
    return this._get(
      `/collections/${collectionId}/usage_histogram?start_date=${startDate}&end_date=${endDate}`,
      {},
      true,
      true
    );
  }

  getCollectionDailyStats(collectionId, startDate, endDate) {
    return this._get(
      `/collections/${collectionId}/daily_stats?start_date=${startDate}&end_date=${endDate}`,
      {}
    );
  }

  getVideoHistogramAnalytics(collectionId, videoId, startDate, endDate, type = "Video") {
    return this._get(
      `/collections/${collectionId}/videos/${videoId}/usage_histogram?start_date=${startDate}&end_date=${endDate}&type=${type}`,
      {},
      true,
      true
    );
  }

  getContentHistogramAnalytics(collectionId, contentId, startDate, endDate, type = "Video") {
    return this._get(
      `/collections/${collectionId}/contents/${contentId}/usage_histogram?start_date=${startDate}&end_date=${endDate}&type=${type}`,
      {},
      true,
      true
    );
  }

  getGuideAnalytics(guideId, startDate, endDate) {
    return this._get(
      `/guides/${guideId}/usage_histogram?start_date=${startDate}&end_date=${endDate}`,
      {},
      true,
      true
    );
  }

  getVideoStats(videoId, startDate, endDate) {
    return this._get(
      `/videos/${videoId}/daily_stats?start_date=${startDate}&end_date=${endDate}`,
      {}
    );
  }

  getEngagementStats(videoId, startDate, endDate, page, size) {
    return this._get(
      `/videos/${videoId}/engagement_stats?start_date=${startDate}&end_date=${endDate}&page=${page}&size=${size}`,
      {},
      true,
      true).then((data) => {
        return new Promise((resolve, reject) => {
          resolve({
            data: this.dataFormatter.deserialize(data) as any,
            meta: data.meta,
          });
        });
      });
  }

  getGuideStats(guideId, startDate, endDate) {
    return this._get(
      `/guides/${guideId}/daily_stats?start_date=${startDate}&end_date=${endDate}`,
      {}
    );
  }

  getVideoStatsAggregate(videoId, startDate, endDate) {
    return this._get(`/videos/${videoId}/daily_stats_aggregate?start_date=${startDate}&end_date=${endDate}`, {}, true, true);
  }

  getGuideStatsAggregate(videoId, startDate, endDate) {
    return this._get(`/guides/${videoId}/daily_stats_aggregate?start_date=${startDate}&end_date=${endDate}`, {}, true, true);
  }

  getVideoFeedback(videoId, startDate, endDate) {
    return this._get(`/videos/${videoId}/daily_stats/video_feedback?start_date=${startDate}&end_date=${endDate}`);
  }

  getVideoAnalytics(videoId) {
    return this._get(`/analytics/${videoId}`, {}, true, true);
  }

  exportVideosAnalytics(startDate, endDate) {
    return this._get(`/export_videos_analytics?start_date=${startDate}&end_date=${endDate}`, {}, true);
  }

  getTopVideos(startDate, endDate) {
    return this._get(
      `/analytics/top_videos?start_date=${startDate}&end_date=${endDate}`,
      {},
      true,
      true
    );
  }

  getTopCollections(startDate, endDate) {
    return this._get(
      `/analytics/top_collections?start_date=${startDate}&end_date=${endDate}`,
      {},
      true,
      true
    );
  }

  getTopCourses(startDate, endDate) {
    return this._get(
      `/analytics/academy/top_courses?start_date=${startDate}&end_date=${endDate}`,
      {},
      true,
      true
    );
  }

  getTotalLearnersCount(startDate, endDate) {
    return this._get(
      `/analytics/total_learners?start_date=${startDate}&end_date=${endDate}`,
      {},
      true,
      true
    );
  }

  getDashboardAnalytics(startDate, endDate) {
    return this._get(
      `/analytics/daily_stats?start_date=${startDate}&end_date=${endDate}`,
      {}
    );
  }

  getOverallAnalytics() {
    return this._get(`/analytics/overall_data`, {}, true, true);
  }

  getOverallAcademyAnalytics() {
    return this._get(`/analytics/academy/overall_data`, {}, true, true);
  }

  getCourseFeedbacks(courseId, page = 1, size = 20, startDate, endDate) {
    return this._get(
      `/courses/${courseId}/feedbacks?page=${page}&size=${size}&start_date=${startDate}&end_date=${endDate}`,
      {},
      true,
      true
    );
  }

  getQuizAttempts(courseId, showLatestAttempt, activityId, page = '1', size = '20', startDate, endDate) {
    const formData = new FormData();
    formData.append('show_latest_attempt', showLatestAttempt);
    if (activityId != 'all') {
      formData.append('activity_id', activityId);
    }
    formData.append('page', page);
    formData.append('size', size);
    formData.append('start_date', startDate);
    formData.append('end_date', endDate);
    return this._post(
      `/courses/${courseId}/quiz_attempts`,
      {},
      formData,
      true,
      true
    ).then((data) => {
      return new Promise((resolve, reject) => {
        resolve({
          result: this.dataFormatter.deserialize(data) as any,
          meta: data.meta,
        });
      });
    });
  }

  getQuizzes(courseId) {
    return this._get(
      `/courses/${courseId}/quizzes`,
      {},
      true,
      true
    );
  }

  getOverallStatsAcademyAnalytics() {
    return this._get(`/academy/analytics/overall_stats`, {}, true, true);
  }

  getDailyStatsAcademyAnalytics(startDate, endDate, companyFilter) {
    const params = `start_date=${startDate}&end_date=${endDate}&company_filter=${companyFilter}`;

    return this._get(
      `/academy/analytics/daily_stats?${params}`,
      {},
      true,
      true
    );
  }

  getTopCompaniesAcademyAnalytics(
    startDate,
    endDate,
    companyFilter: any = null,
    groupByFilter: any = null
  ) {
    const formData = new FormData();

    formData.append('start_date', startDate);
    formData.append('end_date', endDate);
    if (groupByFilter) {
      formData.append('group_by_filter', groupByFilter);
    }
    if (companyFilter) {
      formData.append('company_filter', companyFilter);
    }

    const params = new URLSearchParams(formData as any).toString();
    return this._get(
      `/academy/analytics/top_companies?${params}`,
      {},
      true,
      true
    );
  }

  getTopCoursesAcademyAnalytics(
    startDate,
    endDate,
    companyFilter: any = null,
    groupByFilter: any = null
  ) {
    const formData = new FormData();

    formData.append('start_date', startDate);
    formData.append('end_date', endDate);
    if (groupByFilter) {
      formData.append('group_by_filter', groupByFilter);
    }
    if (companyFilter) {
      formData.append('company_filter', companyFilter);
    }

    const params = new URLSearchParams(formData as any).toString();
    return this._get(
      `/academy/analytics/top_courses?${params}`,
      {},
      true,
      true
    );
  }

  getTopLearnersAcademyAnalytics(
    startDate,
    endDate,
    companyFilter: any = null,
    groupByFilter: any = null
  ) {
    const formData = new FormData();

    formData.append('start_date', startDate);
    formData.append('end_date', endDate);
    if (groupByFilter) {
      formData.append('group_by_filter', groupByFilter);
    }
    if (companyFilter) {
      formData.append('company_filter', companyFilter);
    }

    const params = new URLSearchParams(formData as any).toString();

    return this._get(
      `/academy/analytics/top_learners?${params}`,
      {},
      true,
      true
    );
  }

  getRetentionCohartAcademyAnalytics(startDate, endDate, companyFilter) {
    return this._get(
      `/academy/analytics/retention_cohort?start_date=${startDate}&end_date=${endDate}&company_filter=${companyFilter}`,
      {},
      true,
      true
    );
  }

  getNotificationSwitchStatus() {
    return this._get(`/academy/email_settings/notifications`);
  }

  updateNotificationSetting(name, value, type) {
    return this._put(
      `/academy/email_settings/notifications`,
      {},
      {
        [type]: value,
        name,
      }
    );
  }

  updateNotificationConfig(name, value, type) {
    return this._put(
      `/academy/email_settings/notification_config`,
      {},
      {
        [type]: value,
        name,
      }
    );
  }

  getNotificationContent(name) {
    return this._get(
      `/academy/email_settings/notification_content?name=${name}`,
      {},
      true,
      true
    );
  }

  updateNotificationContent(name, subject, body) {
    return this._put(
      `/academy/email_settings/notification_content`,
      {},
      {
        name,
        subject,
        body,
      },
      true,
      true
    );
  }

  getEmailSetting() {
    return this._get(`/academy/email_settings/email_server`);
  }

  updateEmailSetting(data) {
    return this._post(`/academy/email_settings/email_server`, {}, data, true);
  }

  getCompaniesAcademyAnalytics() {
    return this._get(`/customers/available_companies`, {}, true, true);
  }

  getNarrationVoices() {
    return this._get(`/settings/narration_voices`, {}, true, true);
  }

  getVideoHubSettings(instanceId) {
    return this._get(`/video_hub_settings?instance_id=${instanceId}`);
  }

  disableVideoHub(disabled, instanceId) {
    return this._put(
      `/video_hub_settings?instance_id=${instanceId}`,
      {},
      {
        disabled: disabled,
      }
    );
  }

  // academy end point starts

  enableAcademy(enabled) {
    return this._put(
      `/academy`,
      {},
      {
        enable: enabled,
      }
    );
  }

  getAcademy() {
    return this._get(`/academy`, {});
  }

  getAcademySettings() {
    return this._get(`/academy/settings`, {});
  }

  getAcademyLanguages() {
    return this._get(`/academy/languages`, {}, true, true);
  }

  getCourses(liveOnly = false) {
    return this._get(`/courses?live_only=${liveOnly}`, {});
  }

  createCourse() {
    return this._post(`/courses`, {}, {}, true, true);
  }

  cloneCourse(courseId) {
    return this._post(`/courses/${courseId}/clone`, {}, {});
  }

  createChapter(courseId, name, previousOrder) {
    const data = {
      name,
    };
    if (previousOrder) {
      data.previous_order = previousOrder;
    }
    return this._post(`/courses/${courseId}/chapters`, {}, data);
  }

  createLesson(courseId, chapterId, title?) {
    const data = {};
    if (title) {
      data.title = title;
    }
    return this._post(
      `/courses/${courseId}/chapters/${chapterId}/lessons`,
      {},
      data,
      true,
      true
    );
  }

  previewCourseUrl(courseId) {
    return this._post(
      `/courses/${courseId}/academy_preview_url`,
      {},
      {},
      true,
      true
    );
  }

  getCourse(courseId) {
    return this._get(`/courses/${courseId}`, {});
  }

  getQuiz(quizId) {
    return this._get(`/quizzes/${quizId}`, {});
  }

  getTask(taskId) {
    return this._get(`/lesson_tasks/${taskId}`, {});
  }

  enableQuiz(courseId) {
    return this._post(`/courses/${courseId}/quiz`, {});
  }

  getRunningTask(quizId) {
    return this._get(`/quizzes/${quizId}/task`, {})
  }

  generateQuizQuestions(quizId, data) {
    return this._post(
      `/quizzes/${quizId}/generate`,
      {},
      data,
      true,
      true
    );
  }

  getLesson(courseId, lessonId) {
    return this._get(`/courses/${courseId}/lessons/${lessonId}`, {});
  }

  fetchCoverImage(courseId) {
    return this._get(`/courses/${courseId}/cover_image`, {}, true, true);
  }

  fetchLessons(courseId, chapterId) {
    return this._get(
      `/courses/${courseId}/chapters/${chapterId}/lessons`,
      {},
      true,
      true
    );
  }

  updateCoverImage(courseId, coverImage) {
    const formData = new FormData();
    coverImage && formData.append('file', coverImage);
    return this._put(
      `/courses/${courseId}/cover_image`,
      {},
      formData,
      true,
      true
    );
  }

  updateCoverVideo(courseId, videoId) {
    return this._put(
      `/courses/${courseId}/cover_video`,
      {},
      {
        video_id: videoId,
      },
      true,
      true
    );
  }
  updateCourseTitle(courseId, title) {
    return this._put(`/courses/${courseId}`, {}, { title });
  }

  updateCourse(courseId, data) {
    return this._put(`/courses/${courseId}`, {}, data);
  }

  getStripeProductList() {
    return this._get('/payments/connected/product_list')
  }

  getStripePriceDetails(priceId) {
    return this._get(`/payments/connected/price_details/${priceId}`, {}, true, true)
  }

  stripeAccountSession() {
    return this._post('/payments/connected/account_session', {}, {}, true, true);
  }

  updateAcademySettings(
    logo,
    favicon,
    brandColor,
    gaEnabled,
    gaTrackingId,
    allowGoogleCrawling,
    gaAdditionalDomains,
    moderators,
    languages,
    publicAccessAllowed,
    publicEventAccessAllowed
  ) {
    const formData = new FormData();
    logo && formData.append('logo', logo);
    favicon && formData.append('favicon', favicon);
    brandColor != null && formData.append('brand_color', brandColor);
    gaEnabled != null && formData.append('ga_enabled', gaEnabled);
    gaTrackingId != null && formData.append('ga_tracking_id', gaTrackingId);
    allowGoogleCrawling != null &&
      formData.append('allow_google_crawling', allowGoogleCrawling);
    gaAdditionalDomains &&
      formData.append(
        'ga_additional_domains',
        JSON.stringify(gaAdditionalDomains)
      );
    moderators && formData.append('moderators', JSON.stringify(moderators));
    languages && formData.append('languages', JSON.stringify(languages));
    formData.append('public_access_allowed', publicAccessAllowed);
    formData.append('public_event_access_allowed', publicEventAccessAllowed);

    return this._put(`/academy/settings`, {}, formData);
  }

  updateQuizSettings(quizId, data) {
    return this._put(`/quizzes/${quizId}/settings`, {}, data);
  }

  updateTaskSettings(taskId, data) {
    return this._put(`/lesson_tasks/${taskId}/settings`, {}, data);
  }

  updateCertificateSettings(courseId, data) {
    return this._post(
      `/courses/${courseId}/certification/settings`,
      {},
      data,
      true,
      true
    );
  }
  updateCertificateLogo(courseId, logo) {
    const formData = new FormData();
    formData.append('certificate_logo', logo);
    return this._post(
      `/courses/${courseId}/certification/settings`,
      {},
      formData,
      true,
      true
    );
  }

  deleteCourse(courseId) {
    return this._delete(`/courses/${courseId}`, {}, {}, true, true);
  }

  updateLessonOrder(courseId, chapterId, lessonIds) {
    return this._put(
      `/courses/${courseId}/chapters/${chapterId}/lessons_order`,
      {},
      {
        lesson_ids: lessonIds,
      },
      true,
      true
    );
  }

  publishCourse(courseId) {
    return this._post(`/courses/${courseId}/publish`, {}, {}, true, true);
  }

  publishAcademy() {
    return this._post(`/academy/publish`, {}, {}, true, true);
  }

  deleteLesson(courseId, lessonId) {
    return this._delete(
      `/courses/${courseId}/lessons/${lessonId}`,
      {},
      {},
      true,
      true
    );
  }

  importQuizFromJSON(courseId, questionJson) {
    return this._post(
      `/courses/${courseId}/quiz/import`,
      {},
      {
        template: questionJson,
      },
      true,
      true
    );
  }

  updateChapter(courseId, chapterId, name) {
    return this._put(
      `/courses/${courseId}/chapters/${chapterId}`,
      {},
      {
        name,
      }
    );
  }

  updateLesson(courseId, lessonId, data) {
    return this._put(`/courses/${courseId}/lessons/${lessonId}`, {}, data);
  }

  deleteChapter(courseId, chapterId) {
    return this._delete(
      `/courses/${courseId}/chapters/${chapterId}`,
      {},
      {},
      true,
      false
    );
  }

  associateActivity(courseId, lessonId, activityId, activityType, articleTitle?) {
    return this._put(
      `/courses/${courseId}/lessons/${lessonId}/activity`,
      {},
      {
        activity_id: activityId,
        sync_fields: true,
        activity_type: activityType,
        article_title: articleTitle
      }
    );
  }

  getDownloadableAsset(id) {
    return this._get(`/downloadable_assets/${id}`, {});
  }

  uploadPresentation(courseId, lessonId, activityId, activityType, assetFile) {
    const formData = new FormData();
    formData.append('asset_file', assetFile);
    formData.append('activity_type', activityType);
    formData.append('sync_fields', 'true');
    formData.append('activity_id', activityId);
    return this._put(
      `/courses/${courseId}/lessons/${lessonId}/activity`,
      {},
      formData
    );
  }

  fetchPage(pageName, language) {
    return this._get(`/academy/pages/${pageName}/${language}`, {});
  }

  checkTest(courseId) {
    return this._get(`/tp/courses/${courseId}/quiz/questions/0`, {});
  }

  updatePage(pageName, params) {
    return this._put(`/academy/pages/${pageName}`, {}, params);
  }

  deletePage(pageName) {
    return this._delete(`/academy/pages/${pageName}`, {});
  }

  updatePageCoverImage(pageName, file, language) {
    const formData = new FormData();
    file && formData.append('cover_image', file);
    formData.append('language', language);
    return this._put(`/academy/pages/${pageName}`, {}, formData);
  }

  fetchPageCover(pageName, language) {
    return this._get(
      `/academy/pages/${pageName}/cover_image/${language}`,
      {},
      true,
      true
    );
  }

  addCategory(pageName, language) {
    return this._post(
      `/academy/pages/${pageName}/categories`,
      {},
      { language }
    );
  }

  addLanguagePage(pageName, language) {
    return this._post(`/academy/pages/${pageName}/languages`, {}, { language });
  }

  removeLanguagePage(pageName, language) {
    return this._delete(`/academy/pages/${pageName}/languages/${language}`, {});
  }

  updateCategory(pageName, categoryId, name, language) {
    return this._put(
      `/academy/pages/${pageName}/categories/${categoryId}`,
      {},
      {
        name,
        language,
      }
    );
  }

  updateCategoryCourseOrder(pageName, categoryName, ids, language) {
    return this._put(
      `/academy/pages/${pageName}/categories/${categoryName}/courses_order`,
      {},
      {
        ids,
        language,
      }
    );
  }

  linkCourse(pageName, categoryId, courseId, language) {
    return this._post(
      `/academy/pages/${pageName}/categories/${categoryId}/courses/${courseId}`,
      {},
      { language }
    );
  }

  unlinkCourse(pageName, categoryId, courseId, language) {
    return this._delete(
      `/academy/pages/${pageName}/categories/${categoryId}/courses/${courseId}/${language}`,
      {}
    );
  }

  deleteCategory(pageName, categoryId, language) {
    return this._delete(
      `/academy/pages/${pageName}/categories/${categoryId}/${language}`,
      {},
      {},
      true,
      false
    );
  }

  createPage(name) {
    return this._post(`/academy/pages`, {}, { name }, true, true);
  }

  updateGeneralSettings(
    appUrl,
    authType,
    authMode,
    loginUrl,
    allowGoogleCrawling,
    allowCustomerSignup,
    samlLoginUrl,
    samlCertificate,
    instanceId
  ) {
    return this._put(
      `/video_hub_settings/general?instance_id=${instanceId}`,
      {},
      {
        app_url: appUrl,
        auth_type: authType,
        auth_mode: authMode,
        idp_login_url: loginUrl,
        allow_google_crawling: allowGoogleCrawling,
        allow_customers_to_signup: allowCustomerSignup,
        login_url: samlLoginUrl,
        saml_certificate: samlCertificate,
      }
    );
  }

  updateHubAnalytics(
    enableGa,
    gaTrackingId,
    gaAdditionalDomains,
    gaMeasurementId,
    instanceId
  ) {
    return this._put(
      `/video_hub_settings/analytics?instance_id=${instanceId}`,
      {},
      {
        ga_enabled: enableGa,
        ga_tracking_id: gaTrackingId,
        ga_additional_domains: gaAdditionalDomains,
        ga_measurement_id: gaMeasurementId,
      }
    );
  }

  updateHubTheme(instanceId, favicon?, bannerImage?, logoImage?) {
    const formData = new FormData();
    formData.append('favicon', favicon);
    formData.append('banner', bannerImage);
    formData.append('logo', logoImage);
    return this._put(
      `/video_hub_settings/theme?instance_id=${instanceId}`,
      { 'Content-Type': 'application/x-www-form-urlencoded' },
      formData
    );
  }

  updateHubCopy(title, description, loginDescription, instanceId) {
    return this._put(
      `/video_hub_settings/copy?instance_id=${instanceId}`,
      {},
      {
        title,
        description,
        login_description: loginDescription,
      }
    );
  }

  updateCollectionsHub(learningSetId, collections) {
    return this._put(
      `/learning_set/${learningSetId}/collections`,
      {},
      {
        collections,
      }
    );
  }

  updateCollectionsToCategories(learningSetId, categoryId, collections) {
    return this._put(
      `/learning_set/${learningSetId}/category_views/${categoryId}/collections`,
      {},
      {
        collections,
      }
    );
  }

  deleteCategoryView(learningSetId, categoryId) {
    return this._delete(
      `/learning_set/${learningSetId}/category_views/${categoryId}`
    );
  }

  markAsAdvancedMode(learningSetId, value) {
    return this._put(
      `/learning_set/${learningSetId}`,
      {},
      {
        mark_as_advanced: value,
      }
    );
  }

  addCategoryView(learningSetId, name) {
    return this._post(
      `/learning_set/${learningSetId}/category_views`,
      {},
      {
        name,
      }
    );
  }

  editCategoryView(learningSetId, categoryId, name) {
    return this._put(
      `/learning_set/${learningSetId}/category_views/${categoryId}`,
      {},
      {
        name,
      }
    );
  }

  updateAuthor(id, authors) {
    return this._put(
      `/courses/${id}/authors`,
      {},
      {
        users: authors,
      },
      true,
      true
    );
  }

  updateCategoryOrder(learningSetId, categoryId, order) {
    return this._put(
      `/learning_set/${learningSetId}`,
      {},
      {
        category_views_order: order,
      }
    );
  }

  updateRecommendedVideos(learningSetId, recommendedVideos) {
    return this._put(
      `/learning_set/${learningSetId}/recommended_videos`,
      {},
      {
        recommended_videos: recommendedVideos,
      }
    );
  }

  updateDefaultVideos(learningSetId, defaultVideos) {
    return this._put(
      `/learning_set/${learningSetId}/default_videos`,
      {},
      {
        videos: defaultVideos,
      }
    );
  }

  getDownloadImageUrl(videoId) {
    return this._get(`/videos/${videoId}/images_zip`, {}, true, true);
  }

  getDownloadPdfUrl(videoId) {
    return this._get(`/videos/${videoId}/pdf`, {}, true, true);
  }

  getDownloadAudioUrl(videoId) {
    return this._get(`/videos/${videoId}/audio`, {}, true, true);
  }

  updatePrimaryLanguage(language) {
    return this._put(`/workspaces/primary_lang`, {}, { lang: language });
  }

  getCourseEngagements(courseId, startDate, endDate) {
    return this._get(
      `/courses/${courseId}/engagements?start_date=${startDate}&end_date=${endDate}`,
      {},
      true,
      true
    );
  }
  getCourseEngagementDetails(
    courseId,
    view,
    page = 1,
    size = 20,
    startDate,
    endDate
  ) {
    return this._get(
      `/courses/${courseId}/engagements/${view}?start_date=${startDate}&end_date=${endDate}&page=${page}&size=${size}`,
      {},
      true,
      true
    );
  }

  getAssessmentAttempts(customerId, courseId) {
    return this._get(
      `/customers/${customerId}/courses/${courseId}/quiz_attempts`,
      {},
      true
    );
  }

  getCourseQuizzes(customerId, courseId) {
    return this._get(
      `/customers/${customerId}/courses/${courseId}/course_quizzes`,
      {},
      true
    );
  }

  downloadLearnerQuizAttempts(customerId, courseId) {
    return this._get(
      `/customers/${customerId}/courses/${courseId}/download_quiz_attempts`
    );
  }

  getQuizAttempt(customerId, quizId) {
    return this._get(
      `/customers/${customerId}/quiz/${quizId}/lesson_quiz_attempts`,
      {},
      true
    );
  }

  resetLearnerAttempts(customerId, courseId) {
    return this._post(
      `/customers/${customerId}/courses/${courseId}/reset_attempts`,
      {},
      {},
      true,
      true
    ).then(() => {
      return this.courseEnrolments(customerId);
    });
  }

  getCustomerCustomFields() {
    return this._get(`/customers/custom_fields`, {}, true, true);
  }

  getSearchMeta() {
    return this._get(`/customers/search_meta`, {}, true, true);
  }

  downloadLearners(conditions) {
    return this._post(`/customers/download`, {}, { conditions }, true, true);
  }

  downloadAnalytics(courseId, view, startDate, endDate) {
    return this._get(
      `/courses/${courseId}/engagements/${view}/export?start_date=${startDate}&end_date=${endDate}`,
      {},
      true,
      true
    );
  }

  getQuizQuestions(quizId) {
    return this._get(`/quizzes/${quizId}/questions`, {}, true);
  }

  createQuestion(quizId) {
    return this._post(`/quizzes/${quizId}/questions`, {}, {}, true);
  }

  updateQuestion(quizId, questionId, question) {
    return this._put(
      `/quizzes/${quizId}/questions/${questionId}`,
      {},
      {
        question,
      },
      true
    );
  }

  deleteQuestion(quizId, questionId) {
    return this._delete(`/quizzes/${quizId}/questions/${questionId}`, {}, true);
  }

  reorderQuestions(quizId, questionIds) {
    return this._put(
      `/quizzes/${quizId}/questions/order`,
      {},
      { question_ids: questionIds },
      true
    );
  }

  addChoice(quizId, questionId, choice) {
    return this._post(
      `/quizzes/${quizId}/questions/${questionId}/choices`,
      {},
      { choice },
      true
    );
  }

  addImageChoice(quizId, questionId, choice) {
    return this._post(
      `/quizzes/${quizId}/questions/${questionId}/image_choices`,
      {},
      choice
    );
  }

  initializeStreamUpload(
    mimeType: string,
    skipVideoCreation: boolean | undefined
  ) {
    const body = {
      mime_type: mimeType,
    };
    if (!!skipVideoCreation) {
      body['skip_video'] = true;
    }
    return this._post('/multipart_uploads', {}, body, true, true);
  }

  deleteChoice(quizId, questionId, choiceId) {
    return this._delete(
      `/quizzes/${quizId}/questions/${questionId}/choices/${choiceId}`,
      {},
      true
    );
  }

  fetchAvailableTracks(videoId) {
    return this._get(`/videos/${videoId}/available_tracks`, {}, true, true);
  }

  fetchAvaliableGuideTracks(guideId) {
    return this._get(`/guides/${guideId}/available_tracks`, {}, true, true);
  }

  getArticles() {
    return this._get(`/articles`, {}, true);
  }

  getArticle(id) {
    return this._get(`/articles/${id}`, {}, true);
  }

  getManualTemplates() {
    return this._get('/manual_templates', {}, false, true)
  }

  createArticle(folderId, templateId?) {
    return this._post(`/articles`, {}, { folder_id: folderId, template_id: templateId }, true);
  }

  updateArticle(id, data) {
    return this._put(`/articles/${id}`, {}, data);
  }

  articleAccessControl(shareableLinkId: string, accessControl) {
    return this._put(
      `/articles/shareable_links/${shareableLinkId}/access_control`,
      {},
      {
        access_control: accessControl
      },
      true,
      true,
      true
    )
  }

  updateArticleSEO(id, data) {
    return this._put(`/articles/${id}/seo`, {}, data);
  }

  deleteArticle(id) {
    return this._delete(`/articles/${id}`, {}, true);
  }

  publishArticle(id) {
    return this._put(`/articles/${id}/publish`, {}, true);
  }

  getDraftVersion(id) {
    return this._post(`/articles/${id}/draft`, {}, {});
  }

  getDocument(id) {
    return this._get(`/documents/${id}`, {});
  }

  createDocument({ title, file, folderId }) {
    const formData = new FormData();
    formData.append('document_file', file);
    formData.append('folder_id', folderId);
    formData.append('title', title);

    return this._post(`/documents`, {}, formData, true, true)
      .then((data) => {
        return this.getDocument(data.id);
      })
  }

  updateDocumentSettings(id, canDownload) {
    return this._put(
      `/documents/${id}/update_settings`,
      {},
      {
        can_download: canDownload
      },
      true,
      true
    )
  }

  documentAccessControl(shareableLinkId: string, accessControl) {
    return this._put(
      `/documents/shareable_links/${shareableLinkId}/access_control`,
      {},
      {
        access_control: accessControl
      },
      true,
      true,
      true
    )
  }

  updateDocumentVersion({ title, file, documentId }) {
    const formData = new FormData();
    formData.append('document_file', file);

    if (title) {
      formData.append('title', title);
    }

    return this._put(`/documents/${documentId}`, {}, formData, true, true)
      .then((data) => {
        return this.getDocument(data.id);
      })
  }

  quickDocumentUpdate(guideId: string, title?) {
    return this._put(
      `/documents/${guideId}/quick_update`,
      {},
      { title },
      true,
      true,
      true
    );
  }

  deleteDocument(id) {
    return this._delete(`/documents/${id}`, {}, true);
  }

  updateVideoSEO(id, data) {
    return this._put(`/videos/${id}/seo`, {}, data, true, true);
  }

  updateVideoSubtitles(videoId: string, subtitles) {
    return this._put(
      `/videos/${videoId}/subtitles`,
      {},
      { subtitles },
      true,
      true
    );
  }

  fetchVideoSubtitles(videoId: string) {
    return this._get(`/videos/${videoId}/subtitles`, {}, true, true).then(
      (data) => {
        return new Promise((resolve, reject) => {
          resolve({
            data: this.dataFormatter.deserialize(data) as any,
            meta: data.meta,
          });
        });
      }
    );
  }

  updateVersionSubtitle(videoId: string, subtitleId: number, params) {
    return this._put(
      `/videos/${videoId}/subtitles/${subtitleId}`,
      {},
      params,
      true,
      true
    );
  }

  pulishVersionSubtitles(videoId: string) {
    return this._put(
      `/videos/${videoId}/subtitles/publish`,
      {},
      {},
      true,
      true
    );
  }

  regenerateVersionSubtitles(videoId: string) {
    return this._put(
      `/videos/${videoId}/subtitles/generate`,
      {},
      {},
      true,
      true
    );
  }

  updateSubtitleMode(videoId: string, mode: string) {
    return this._put(
      `/videos/${videoId}/subtitles/mode`,
      {},
      { mode },
      true,
      true
    );
  }

  addSubtitleLang(videoId: string, lang: string) {
    return this._post(
      `/videos/${videoId}/subtitles/languages`,
      {},
      {
        lang,
      },
      true,
      true
    );
  }

  removeSubtitleLang(videoId: string, lang: string) {
    return this._delete(
      `/videos/${videoId}/subtitles/languages`,
      {},
      {
        lang,
      },
      true,
      true
    );
  }

  updateVideoCtas(videoId: string, ctas) {
    return this._put(`/videos/${videoId}/cta_links`, {}, { ctas }, true, true);
  }

  updateVideoChapters(videoId: string, chapters) {
    return this._put(
      `/videos/${videoId}/chapters`,
      {},
      { chapters },
      true,
      true
    );
  }

  getTrainingEvents(liveOnly = false) {
    return this._get(`/training_events?live_only=${liveOnly}`, {});
  }

  createTrainingEvent() {
    return this._post('/training_events', {}, {}, true);
  }

  getTrainingEvent(trainingEventId: string) {
    return this._get(
      `/training_events/${trainingEventId}`,
      {}
    )
  }

  updateTrainingEvent(trainingEventId: string, data) {
    return this._put(
      `/training_events/${trainingEventId}`,
      {},
      data
    )
  }

  trainingEvents(sortOrder = 'asc') {
    return this._get(
      `/training_events?sort_order=${sortOrder}`
    )
  }

  inviteCustomersToEvents(trainingEventId: string, trainingSessionId: string, customerIds: []) {
    return this._post(
      `/training_events/${trainingEventId}/sessions/${trainingSessionId}/invite`,
      {},
      {
        customer_ids: customerIds
      }
    )
  }

  createTrainingSession(trainingEventId: string, data = {}) {
    return this._post(`/training_events/${trainingEventId}/sessions`, {}, data);
  }

  getTrainingSession(trainingEventId: string, sessionId: string) {
    return this._get(
      `/training_events/${trainingEventId}/sessions/${sessionId}`,
      {}
    )
  }

  updateTrainingSession(trainingEventId: string, sessionId: string, data) {
    return this._put(
      `/training_events/${trainingEventId}/sessions/${sessionId}`,
      {},
      data
    )
  }

  upcomingTrainingSessions(trainingEventId: string) {
    return this._get(
      `/training_events/${trainingEventId}/upcoming_sessions`
    )
  }

  pastTrainingSessions(trainingEventId: string) {
    return this._get(
      `/training_events/${trainingEventId}/past_sessions`
    )
  }

  cancelTrainingSession(trainingEventId: string, sessionId: string) {
    return this._put(
      `/training_events/${trainingEventId}/sessions/${sessionId}/cancel`,
      {}
    )
  }

  deleteTrainingEvent(trainingEventId: string) {
    return this._put(
      `/training_events/${trainingEventId}/delete`,
      {}
    )
  }

  getTrainingEventInstructions(trainingEventId: string, sessionId: string) {
    return this._get(
      `/training_events/${trainingEventId}/sessions/${sessionId}/instructors`)
  }

  updateTrainingEventInstructors(trainingEventId: string, sessionId: string, users: []) {
    return this._put(
      `/training_events/${trainingEventId}/sessions/${sessionId}/instructors`,
      {},
      {
        users
      }
    )
  }

  getTrainingEventLearners(trainingEventId: string, sessionId: string, status = '') {
    return this._get(
      `/training_events/${trainingEventId}/sessions/${sessionId}/learners?status=${status}`)
  }

  private _get(
    path: string,
    headers = {},
    tenancy = true,
    skipConversion = false,
    auth = true
  ) {
    const url = this.resolveUrl(tenancy);
    if (auth) {
      headers['Authorization'] = `Bearer ${this.authService.jwtToken}`;
    }
    return this.handleError(
      axios({
        method: 'GET',
        url: url + path,
        // timeout: 6000,
        headers: Object.assign(
          {
            'Content-Type': 'application/json',
          },
          headers
        ),
      }).then(({ data }) => {
        return skipConversion
          ? data
          : (this.dataFormatter.deserialize(data) as any);
      })
    );
  }

  private _delete(
    path: string,
    headers = {},
    body = {},
    tenancy = true,
    skipConversion = true,
    auth = true
  ) {
    const url = this.resolveUrl(tenancy);
    if (auth) {
      headers['Authorization'] = `Bearer ${this.authService.jwtToken}`;
    }
    return this.handleError(
      axios({
        method: 'DELETE',
        url: url + path,
        // timeout: 6000,
        headers: Object.assign(
          {
            'Content-Type': 'application/json',
          },
          headers
        ),
        data: body,
      }).then(({ data }) => {
        return skipConversion
          ? data
          : (this.dataFormatter.deserialize(data) as any);
      })
    );
  }

  private _put(
    path: string,
    headers = {},
    body = {},
    tenancy = true,
    skipConversion = false,
    auth = true,
    cancelToken = undefined
  ) {
    const url = this.resolveUrl(tenancy);
    if (auth) {
      headers['Authorization'] = `Bearer ${this.authService.jwtToken}`;
    }
    return this.handleError(
      axios({
        method: 'PATCH',
        url: url + path,
        // timeout: 6000,
        headers: Object.assign(
          {
            'Content-Type': 'application/json',
          },
          headers
        ),
        data: body,
        cancelToken,
      }).then(({ data }) => {
        return skipConversion
          ? data
          : (this.dataFormatter.deserialize(data) as any);
      })
    );
  }

  private _post(
    path: string,
    headers = {},
    body = {},
    tenancy = true,
    skipConversion = false,
    auth = true
  ) {
    const url = this.resolveUrl(tenancy);
    if (auth) {
      headers['Authorization'] = `Bearer ${this.authService.jwtToken}`;
    }
    return this.handleError(
      axios({
        method: 'POST',
        url: url + path,
        // timeout: 6000,
        headers: Object.assign(
          {
            'Content-Type': 'application/json',
          },
          headers
        ),
        data: body,
      }).then(({ data }) => {
        return skipConversion
          ? data
          : (this.dataFormatter.deserialize(data) as any);
      })
    );
  }

  private handleError(promise) {
    return promise.catch((error) => {
      if (!error.response) {
        throw error;
        // TODO show network connectivity
      }
      if (error.response.status == 401) {
        this.authService.logout();
      }
      throw new TubeError(error.response.data, error.response.status);
    });
  }

  private resolveUrl(tenancy) {
    let url;
    if (tenancy) {
      if (this.edgeApiEnabled) {
        url = WorkspaceUtils.workspaceEdgeApiUrl(this.workspaceName)
      }
      else {
        url = WorkspaceUtils.workspaceApiUrl(this.workspaceName)
      }
    }
    else {
      url = WorkspaceUtils.commonApiUrl();
    }
    return url
  }
}

export default new ApiClient();
