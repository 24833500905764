export default {
  loading: 'Loading...',
  fetching: 'Fetching...',
  cloning: 'Cloning...',
  deleting: 'Deleting...',
  uploading: 'Uploading...',
  saving: 'Saving...',
  no_access:
    'You do not have access to perform this action. Get access from your admin.',
  not_eligible: 'Your current plan does not support this action. Upgrade!',
  move: 'Move to...',
  info: {
    publish_to_youtube: 'We will email you once your video has been published!',
    check_email:
      'A CSV file containing the learner quiz information will be sent to your email.',
    regenarate_description_info: 'Generating description...'
  },
  error: {
    default: 'Sorry something went wrong while processing the request',
    missing_sign_up_token: 'Invalid sign up token',
    signup_failed: 'Sorry! We are unable to sign up',
    login_failed: 'Sorry! We are unable to login',
    sso_not_enabled: 'Sorry! Single Sign-On not enabled for this domain',
    invalid_domain: 'Enter your work email.',
    incognito_login:
      'For google authentication to work, you need to enable third party cookies for the domain “accounts.google.com”. You can enable them in Settings → Site Settings → Cookies',
    unauthorized_login:
      'Sorry! you are not authorized to access this workspace',
    account_not_found:
      "Sorry! It seems you don't have an account with us. Please sign up to continue.",
    workspace_not_found:
      'You do not have active workspace. Please sign up to continue.',
    invalid_password: 'Incorrect password',
    invalid_old_password: 'Incorrect old password',
    clone_video_failed: 'Your video cloning has failed',
    clone_guide_failed: 'Your guide cloning has failed',
    download_transcript_failed: 'Downloading transcript has failed',
    download_video_failed: 'Downloading video has failed',
    upload_csv_failed: 'Uploading learners csv has failed',
    generate_snippets_failed: 'Generating snippets from video has failed',
    empty_fields: 'One or many of the fields are empty',
    empty_course_cover: 'Course cover is empty',
    pdf_error:
      'There was an error while loading the PDF. Please try refreshing the page.',
    pdf_not_found: 'This PDF is no longer available',
    download_response_failed: 'Downloading learner response has failed',
    export_email_logs: 'Exporting email logs has failed',
    export_video_analytics_failed: 'Exporting videos analytics has failed',
    assist_user_notes: ' Sorry, Unable to suggest texts',
    questions_gen_failed: 'Sorry, Unable to generate the questions',
    regenerate_description_failed: 'Description generation failed'
  },
  success: {
    edit_theme: 'Theme edited successfully',
    workspace_name: 'Workspace name updated successfully',
    delete_collection: 'Collection has been deleted successfully!',
    collection_created: 'Collection created successfully',
    delete_video: 'Your video has been deleted successfully!',
    clone_guide: 'Your guide has been cloned successfully',
    clone_video: 'Your video has been cloned successfully!',
    delete_guide: 'Your guide has been deleted successfully!',
    delete_article: 'Your article has been deleted successfully!',
    delete_document: 'Your document has been deleted successfully! ',
    delete_folder: 'Your folder has been deleted successfully',
    reinvited: 'Invitation has been sent',
    transcript_download: 'Your transcript has been downloaded successfully',
    delete_customer: 'Customer has been deleted successfully!',
    invitation_sent: 'Invitation has been sent via email',
    upload_csv: `%{count} record(s) has been successfully inserted`,
    restore_video: 'Your video has been restored successfully!',
    restore_folder:
      'Your folder and all of its content has been restored successfully!',
    restore_content: 'Your item has been restored successfully!',
    account: 'Account updated successfully',
    access_granted: 'Access granted successfully',
    regenerate_description: 'Description generated successfully',
    export_video_analytics: 'Videos analytics downloaded successfully',
    internal_name: 'Internal name updated successfully',
    rollback: 'Video changes rolled back successfully'
  },
  video_buffering: 'The playback may be affected due to slow internet connection',
  unsaved_changes: 'You have unsaved changes',
  back_to_all: 'Back to all options',
  go_back_force: 'Go back anyway'
};
